
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
import { teamList } from '../../Services/User';  
import { logs } from '../../Services/Utils';
import useToken from '../App/useToken';
import Footer from '../Layout/Footer'; 
import { useTranslation } from 'react-i18next'; 
import { Picker, Field } from 'react-vant'  

import InnerNav from '../InnerNav';   
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 


export default function TeamList() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [alllog, setAlllog] = useState(false); 
    const [rechargelog, setRechargelog] = useState(false); 
    const [withdrawlog, setWithdrawlog] = useState(false);  
    const [list, setList] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); 
    const [activeTab, setActiveTab] = useState('members');
    const [level, setLevel] = useState(1); 
    const [days, setDays] = useState(1); 
    
    const [levelValue, setLevelValue] = useState(1); 
    const [levelTitle, setLevelTitle] = useState("Level 1"); 
    const [daysValue, setDaysValue] = useState(1); 
    const [daysTitle, setDaysTitle] = useState("Today"); 
    const [memberToday, setMemberToday] = useState(0); 
    const [incomeToday, setIncomeToday] = useState(0); 
 
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }  
        getTeamLists(activeTab, currentPage)
        // eslint-disable-next-line
    }, [ currentPage]); 
  
    const getTeamLists = (type, page) => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {   
            setActiveTab(type); 
            setList([]);
            let token   = tokenInfo;   
            let days   = daysValue;   
            let level   = levelValue;   
            teamList({
                page,
                type,
                token,
                days,
                level
            }, CallbackLogs);  
        }
    } 
     
    const CallbackLogs = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;   
            logs(lists)
            setList(lists.data);
            setCurrentPage(lists.current_page);
            setTotalPages(lists.last_page); 
            setMemberToday(lists.memberToday); 
            setIncomeToday(lists.incomeToday); 
        } 
    } 
    
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
          setCurrentPage(newPage);
        }
    };
    
    const columnDays = [
        {'title':t('All'), 'value':0},
        {'title':t('Today'), 'value':1},
        {'title':t('Last 3 days'), 'value':3},
        {'title':t('Last 7 days'), 'value':7},
        {'title':t('Last 15 days'), 'value':15},
        {'title':t('Nearly 30 days'), 'value':30},
    ]
    
    const columnLevel = [ 
        {'title':t('Level 1'), 'value':1},
        {'title':t('Level 2'), 'value':2},
        {'title':t('Level 3'), 'value':3},
    ]
    
    const filterLevel = (dtitle, dday) => {   
        setLevelValue(dday)
        setLevelTitle(dtitle) 
    }
    const filterDay = (dtitle, dday) => {   
        setDaysValue(dday)
        setDaysTitle(dtitle) 
    }


    return (
    <>
        <div className="page-wrapper record-wrapper"> 
            
            <InnerNav title={t('Team list')}/>
        
            <div className="card-wrapper"> 
                <div className="inner-tab-nav">
                    <span
                        className={`nav-tab ${activeTab === 'members' ? 'active' : ''}`}
                        onClick={() => getTeamLists('members', 1)}
                    >
                        {t('Team members')}
                    </span>
                    <span
                        className={`nav-tab ${activeTab === 'contribution' ? 'active' : ''}`}
                        onClick={() => getTeamLists('contribution', 1)}
                    >
                        {t('Team contribution')}
                    </span> 
                </div>

                <div className="record-lists"> 
                    {activeTab === "members"  ?(
                        <div className="card-wrapper-header">
                            <h4>{t("Registered people today")}: {memberToday}</h4>
                            <span className="filter">
                                <Picker     
                                popup={{
                                    round: true,
                                }}
                                value={levelValue} 
                                columns={columnLevel}
                                columnsFieldNames={{
                                    text: 'title', value: 'value'
                                }}
                                onChange={(val, selectRow, index) => {   
                                    if(selectRow !== undefined){
                                        filterLevel(selectRow.title, selectRow.value);
                                    }
                                }}
                                onConfirm={(val, selectRow, index) => {  
                                    getTeamLists(activeTab, currentPage) 
                                }}
                                confirmButtonText={t("confirm")}
                                cancelButtonText={t("cancel")}
                                placeholder={t("please select")}
                                >
                                {(val, _, actions) => { 
                                    return (
                                    <Field
                                        readOnly
                                        clickable 
                                        value={val || ''}
                                        onClick={() => actions.open()}
                                    >{ _.title}</Field>
                                    )
                                }}
                                </Picker>
                            </span>
                        </div> 
                    ) : ( 
                        <div className="card-wrapper-header">
                            <h4>{t("Cumulative income today")}: {incomeToday}</h4>
                            <span className="filter">
                                <Picker     
                                popup={{
                                    round: true,
                                }}
                                value={daysValue} 
                                columns={columnDays}
                                columnsFieldNames={{
                                    text: 'title', value: 'value'
                                }}
                                onChange={(val, selectRow, index) => {   
                                    if(selectRow !== undefined){
                                        filterDay(selectRow.title, selectRow.value);
                                    }
                                }}
                                onConfirm={(val, selectRow, index) => { 
                                    getTeamLists(activeTab, currentPage) 
                                }}
                                confirmButtonText={t("confirm")}
                                cancelButtonText={t("cancel")}
                                placeholder={t("please select")}
                                >
                                {(val, _, actions) => { 
                                    return (
                                    <Field
                                        readOnly
                                        clickable 
                                        value={val || ''}
                                        onClick={() => actions.open()}
                                    >{ _.title}</Field>
                                    )
                                }}
                                </Picker>
                            </span>
                        </div> 
                    )} 
                    <table className="table">
                        <thead>
                            {activeTab === "members" ? ( 
                                <tr>
                                    <th>{t("Account")}</th>
                                    <th>{t("Level")}</th>
                                    <th>{t("Grade")}</th>
                                    <th>{t("Registration time")}</th>
                                </tr>
                            ) : ( 
                                <tr>
                                    <th>{t("Account")}</th>
                                    <th>{t("Level")}</th>
                                    <th>{t("Time")}</th>
                                    <th>{t("Income")}</th>
                                </tr>
                            )}
                        </thead>
                        <tbody>
                    
                        {list.length > 0 ? (
                            list.map(item => (
                                <tr key={item.id}>
                                    {activeTab === "members" ? (
                                        <>
                                            <td>{item.accountName}</td>
                                            <td>{t(item.levelName)}</td>
                                            <td>{item.vipName}</td>
                                            <td>{item.datetime}</td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{item.accountName}</td>
                                            <td>{t(item.levelName)}</td>
                                            <td>{item.datetime}</td>
                                            <td>{item.amount}</td>
                                        </>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">{t('No record available')}</td>
                            </tr>
                        )}


                        </tbody>
                    </table> 
                </div> 
            </div> 
 
                                    
            {list.length > 0 ? (           
                <div className="paginator">
                        <span
                            className={`paginator-link ${currentPage === 1 ? 'disabled' : ''}`}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-chevron-left"></i>
                        </span>
                        <span>{t('Page')} {currentPage} / {totalPages}</span>
                        <span 
                            className={`paginator-link ${currentPage === totalPages ? 'disabled' : ''}`} 
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </div>
                ): null}
 
        </div>
        <Footer/>
    </>
    );
};