import * as React from 'react';
import { useNavigate, useLocation  } from "react-router-dom"; 
import { useTranslation } from 'react-i18next';
 
export default function Footer(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const path = currentPath.substring(1);
 
    const home = () => { 
        navigate('/home');
    }; 
 
    const quantify = () => { 
        navigate('/quantify');
    }; 

    const team = () => { 
        navigate('/team-report');
    }; 

    const mining = () => { 
        navigate('/mining');
    }; 

    const my = () => { 
        navigate('/my');
    }; 
 
    const { t } = useTranslation();

    return (
        <div className="nav-footer"> 
            <div onClick={home} className={`nav-footer-items ${path === '' ? 'active' : ''} `}>
                <span className="footer-icon">
                {path === '' ? 
                    <img src="/images/home_active.png" alt={t('Home')} height="20"/>
                    : 
                    <img src="/images/home.png" alt={t('Home')} height="20"/>
                }
                </span> 
                <span>{t('Home')}</span>
            </div> 

            <div onClick={quantify} className={`nav-footer-items ${path === 'quantify' ? 'active' : ''} `}>
                <span className="footer-icon"> 
                {path === 'quantify' ? 
                    <img src="/images/lianghua_active.png" alt={t('Home')} height="20"/>
                    : 
                    <img src="/images/lianghua.png" alt={t('Home')} height="20"/>
                } 
                </span>
                <span>{t('Quantify')}</span>
            </div>

            <div onClick={team} className={`nav-footer-items ${path === 'team-report' ? 'active' : ''} `}>
                <span className="footer-icon"> 
                {path === 'team-report' ? 
                    <img src="/images/myteam_active.png" alt={t('myteam')} height="20"/>
                    : 
                    <img src="/images/myteam.png" alt={t('myteam')} height="20"/>
                } 
                </span>
                <span>{t('Team')}</span>
            </div>
            
            <div onClick={mining} className={`nav-footer-items ${path === 'mining' ? 'active' : ''} `}>
                <span className="footer-icon"> 
                {path === 'mining' ? 
                    <img src="/images/wakuang_active.png" alt={t('Mining')} height="20"/>
                    : 
                    <img src="/images/wakuang.png" alt={t('Mining')} height="20"/>
                } 
                </span>
                <span>{t('Mining')}</span>
            </div>

            <div onClick={my} className={`nav-footer-items ${path === 'my' ? 'active' : ''} `}>
                <span className="footer-icon"> 
                {path === 'mining' ? 
                    <img src="/images/my_active.png" alt={t('My')} height="20"/>
                    : 
                    <img src="/images/my.png" alt={t('My')} height="20"/>
                } 
                </span>
                <span>{t('Mine')}</span>
            </div> 
        </div> 
      );
} 