
import React, {useEffect,useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom'   
import { useTranslation } from 'react-i18next'; 

import Footer from '../Layout/Footer';
import LoadingSpinner from '../LoadingSpinner';  
import { logs } from '../../Services/Utils';
import { miningDetail, Profile, miningPurchase} from '../../Services/User';  

import { 
    Button,
    Row ,
    Col,
    CardBody,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from "reactstrap";
  
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 8000}); 

export default function MiningPurchase() {  
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const { id } = useParams();
 
    const [loading, setLoading] = React.useState(false)    
    const [balance, setBalance] = useState(0);  
    const [detail, setDetail] = useState([]);
    const [mineDays, setMineDays] = useState([]);
    const [mineMinAmount, setMineMinAmount] = useState([]);
    const [mineMaxAmount, setMineMaxAmount] = useState([]);
    const [returnRate, setReturnRate] = useState(0);
    const [coinTitle, setCoinTitle] = useState("");
    const [earlyRedemption, setEarlyRedemption] = useState("0.00");
    const [estAmount, setEstAmount] = useState(0);
    const [amount, setAmount] = useState(0);
     
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }     
        getMiningDetail(); 
        getUserProfile(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
     
    useEffect(() => {
        let est_income = amount * returnRate/100;
        setEstAmount(parseFloat(est_income.toFixed(2)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount]); 
      
    const getUserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;  
            setBalance(parseFloat(data.balance));   
        } 
    } 
 
    const getMiningDetail = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {    
            let token   = tokenInfo;  
            miningDetail({ 
                token,
                id
            }, CallbackMiningDetail);  
        }
    } 
    
    const CallbackMiningDetail = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var detail = returnData.data;    
            logs(detail);  

            setMineMinAmount(detail.limit_min);  
            setMineMaxAmount(detail.limit_max);  
            setMineDays(detail.days);  
            setReturnRate(detail.return_rate);  
            setCoinTitle(detail.title);  
        } 
    } 
 
    const goto = (path) => { 
        navigate('/' + path);
    }; 
  
    const handleGoBack = () => {
        window.history.back();
    };
     
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    }   

    const allAmount = () => {  
        setAmount(balance)
    }     

    const handleForm = async e => { 
        e.preventDefault();      

        if(amount > balance){ 
            notyf.error(t("Mining amount cannot more than balance")+"(USDT "+ balance + ")");
            return false;   
        }
        if(amount < mineMinAmount){ 
            notyf.error(t("Min mining amount cannot less than USDT ")+mineMinAmount);
            return false;   
        }
        if(amount > mineMaxAmount){ 
            notyf.error(t("Max mining amount cannot more than USDT ")+mineMaxAmount);
            return false;   
        }
         
        setLoading(true);  
        let token = localStorage.getItem('token');  
        miningPurchase({ 
            token,
            amount,
            id
        }, CallbackHandleForm);
    } 

    const CallbackHandleForm = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        {  
            notyf.success(t(returnData.message));  
            goto("mining-logs");
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 

    return (
        <>
        <div className="content-page"> 
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper mining-wrapper">   
                <div className="app_wrapper">
                    <div className="mining_detail">
                        <div className="top-header" >
                            <div className="inner-top-nav">
                                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                                <span>{t('Details')}</span>
                                <span></span>
                            </div>
                        </div>
                        
                        <div className="mining_detail_top">
                            <div className="mining_detail_info">
                                <div className="mining_detail_header">                        
                                    <div className="mining_title">
                                        <img src={"/images/usdt.png"} height={60}/>
                                        <span>
                                            <h4>{coinTitle}</h4>
                                            <h4>{t("Cloud mining machine")}</h4>
                                        </span>
                                    </div>
                                </div>
                                <div className="mining_detail_header right">
                                    <h4>{t("Daily income")}</h4>
                                    <h5>{returnRate}%</h5>
                                </div>
                            </div>
                        </div>
                        <div className="mining_detail_bottom">
                            <Row>
                                <Col>
                                    <span className="item_value">{mineDays}({t("Sky")})</span>
                                    <h4 className="item_title">{t("Escrow period")}</h4>
                                </Col>
                                <Col>
                                    <span className="item_value">{mineMinAmount}</span>
                                    <h4 className="item_title">{t("Minimum single transaction")}</h4>
                                </Col>
                                <Col>
                                    <span className="item_value">{mineMaxAmount}</span>
                                    <h4 className="item_title">{t("Most single transaction")}</h4>
                                </Col>
                            </Row> 
                        </div>
                    </div>

                    <form className="form" onSubmit={handleForm} >   
                        <div className="mining_form"> 
                            <div className="mining_form_row">
                                <Row>
                                    <Col>
                                        <h4 className="item_title">{t("Dividend payment time")}</h4>
                                        <h4 className="item_value">{t("Daily")}</h4>
                                    </Col>
                                    <Col>
                                        <h4 className="item_title">{t("Escrow funds")}</h4>
                                        <h4 className="item_value">{t("Return upon expiration")}</h4>
                                    </Col>
                                    <Col>
                                        <h4 className="item_title">{t("Early redemption")}</h4>
                                        <h4 className="item_value">{earlyRedemption}%</h4>
                                    </Col>
                                </Row> 
                            </div>
                            <div className="mining_form_row">
                                <Row>
                                    <Col className="left">
                                        <h4 className="item_title">{t("Estimated revenue(USDT)")}</h4>
                                        <h4 className="item_value">{estAmount}</h4>
                                    </Col> 
                                    <Col className="right">
                                        <h4 className="item_title">{t("Available assets(USDT)")}</h4>
                                        <h4 className="item_value">{balance}</h4>
                                    </Col>
                                </Row> 
                            </div>
                            <div className="mining_form_row">
                                <label>{t("Escrow amount")}</label>
                                <div className="mining_form_input">
                                    <Input 
                                        placeholder="0"  
                                        name="amount"
                                        value={amount}
                                        min={0}
                                        max={balance}
                                        type={"number"}
                                        onChange={(e)=>inputChangeHandler(setAmount, e)}    
                                    />
                                    <span>
                                        <span className="input_label">{t("USDT")}</span>
                                        <span className="input_btn" onClick={allAmount}>{t("All")}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
    
                        <div className="mining_form_action"> 
                            <Button type="submit" className="btn btn-primary">{t('Apply for subscription')}</Button> 
                        </div>  
                    </form> 
                </div>  
            </div> 
        </div>  
        </>
    );
};