import React from 'react';   
import { useTranslation } from 'react-i18next'; 

import { 
  Button,
  Row ,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input, 
  InputGroupText,
  InputGroup,
  Container, 
} from "reactstrap";

function MiningLogComponent({ item, getOrderInfo }) {
 
  const { t } = useTranslation();
  return (
    <div>
        <div className="records_item"  key={item.id}>
          {item !== null && ( 
            <div className="card mb-3">
              <div className="card-body"> 
              <div className="quant-log">
                <div className="quant-log-header"> 
                  <h4> 
                    <span>{item.title}</span>
                  </h4>
                  <span className="link-status">{item.status} <i className="fa-solid fa-chevron-right"></i></span>
                </div>
                <Row>
                    <Col className="left">
                        <span className="item_title">{t("Order Number")}</span>
                        <span className="item_value">{item.order_number}</span>
                    </Col>
                    <Col className="center">
                        <span className="item_title">{t("Amount")}</span>
                        <span className="item_value">{item.amount}</span>
                    </Col>
                    <Col className="right">
                        <span className="item_title">{t("Days")}</span>
                        <span className="item_value">{item.days}</span>
                    </Col>
                </Row> 
                <Row>
                    <Col className="left">
                        <span className="item_title">{t("Time")}</span>
                        <span className="item_value">{item.datetime}</span>
                    </Col>
                    <Col className="center">
                        <span className="item_title">{t("Return")}</span>
                        <span className="item_value">{item.return_rate}%</span>
                    </Col>
                    <Col className="right">
                        <span className="item_title">{t("Payout Day(count)")}</span>
                        <span className="item_value">{item.current_day}</span>
                    </Col>
                </Row>  
              </div> 
              </div>
            </div>
          )} 
        </div>
    </div>
  );
}

export default MiningLogComponent;
