
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; 
import "notyf/notyf.min.css"; 
import '../../styles/main.scss';
import "../../assets/fontawesome/css/all.css";

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Home from '../Home/Home'; 
import Service from '../Home/Service';  
import Record from '../Home/Record'; 
import My from '../Home/My'; 
import Quantify from '../Home/Quantify'; 
import Mining from '../Home/Mining'; 
import MiningPurchase from '../Home/MiningPurchase'; 
import MiningLogs from '../Home/MiningLogs'; 
 

import Account from '../Home/Account'; 
import Bank from '../Home/Bank'; 
import PasswordWithdraw from '../Home/PasswordWithdraw'; 
import Password from '../Home/Password'; 
import Language from '../Home/Language'; 
import Realname from '../Home/Realname'; 

import Recharge from '../Home/Recharge'; 
import RechargeTron from '../Home/RechargeTron'; 
import RechargeType from '../Home/RechargeType'; 
import RechargeLogs from '../Home/RechargeLogs'; 
import Withdrawal from '../Home/Withdrawal'; 
import WithdrawalLogs from '../Home/WithdrawalLogs'; 
import TransactionLogs from '../Home/TransactionLogs'; 
import QuantLog from '../Home/QuantLog'; 
import Invite from '../Home/Invite'; 
import Profile from '../Home/MyProfile'; 
import Vip from '../Home/Vip'; 
import TeamReport from '../Home/TeamReport'; 
import TeamList from '../Home/TeamList'; 
import PrivacyPolicy from '../Home/PrivacyPolicy'; 
import TermsCondition from '../Home/TermsCondition'; 
import OrderInfo from '../Home/OrderInfo'; 
import QnA from '../Home/QnA'; 
import About from '../Home/About'; 
import Tutorial from '../Home/Tutorial'; 
import Rules from '../Home/Rules'; 
import FAQ from '../Home/FAQ'; 

import Login from '../Login/Login';
import Register from '../Login/Register';  
import PageNotFound from '../Layout/PageNotFound';   

import i18n from 'i18next'; // Import i18next
import { useTranslation } from 'react-i18next';
import { initReactI18next } from 'react-i18next';


import enTranslations from '../i18n/en.json';
import zhTranslations from '../i18n/zh.json';
import cnTranslations from '../i18n/cn.json'; 
import vnTranslations from '../i18n/vn.json';
import esTranslations from '../i18n/es.json';
 
// Initialize i18next with translations
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      zh: { translation: zhTranslations }, 
      cn: { translation: cnTranslations },
      vn: { translation: vnTranslations },
      es: { translation: esTranslations }, 
    },
    lng: localStorage.getItem('lang') || 'zh',
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false,
    },
});

const theme = createTheme({
    palette: {
      mode: 'light',
    },
});
 
function App() { 
    // Initialize the language based on localStorage on app load
    useEffect(() => {
      const storedLang = localStorage.getItem('lang'); 
      if (storedLang) {
        i18n.changeLanguage(storedLang);
      }
    }, []);


    return(
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <div className="wrapper"> 
                <Router>
                    <Routes > 
                        <Route  path="*" element={<Navigate to="404" />} />
                        <Route  path="/*" element={<PageNotFound />} />
                        
                        <Route  exact path="/" element={<Home/>}  /> 
                        
                        <Route  path="/login" element={<Login/>} />
                        <Route  path="/register/:inviteCode?" element={<Register />} /> 
                        <Route  path="/404" element={<PageNotFound/>}  />
                        
                        <Route  path="/home" element={<Home/>}  />
                        <Route  path="/service" element={<Service/>}  />
                        <Route  path="/record" element={<Record/>}  />
                        <Route  path="/my" element={<My/>}  />

                        <Route  path="/account" element={<Account/>}  />
                        <Route  path="/bank" element={<Bank/>}  />
                        <Route  path="/password" element={<Password/>}  />
                        <Route  path="/withdraw-password" element={<PasswordWithdraw/>}  />
                        <Route  path="/language" element={<Language/>}  />
                        <Route  path="/realname" element={<Realname/>}  />

                        <Route  path="/withdrawal" element={<Withdrawal/>}  />
                        <Route  path="/withdrawal-history" element={<WithdrawalLogs/>}  />
                        
                        <Route  path="/deposit-type" element={<RechargeType/>}  />
                        <Route  path="/deposits/:type" element={<Recharge/>}  />
                        <Route  path="/deposit-history" element={<RechargeLogs/>}  />
                        <Route  path="/deposit-form" element={<Recharge/>}  />
                        <Route  path="/deposit" element={<RechargeTron/>}  />

                        <Route  path="/transaction-logs" element={<TransactionLogs/>}  />
                        <Route  path="/quant-logs" element={<QuantLog/>}  />
                        <Route  path="/mining-logs" element={<MiningLogs/>}  />
                        <Route  path="/team-report" element={<TeamReport/>}  />
                        <Route  path="/team-list" element={<TeamList/>}  />
                        
                        <Route  path="/invite" element={<Invite/>}  />
                        <Route  path="/profile" element={<Profile/>}  />
 
                        <Route  path="/mining" element={<Mining/>}  />
                        <Route  path="/quantify" element={<Quantify/>}  />
                        <Route  path="/mining-purchase/:id?" element={<MiningPurchase/>}  />

                        <Route  path="/packages" element={<Vip/>}  />
                        <Route  path="/order/:orderNumber?" element={<OrderInfo/>}  />

                        <Route  path="/privacy-policy" element={<PrivacyPolicy/>}  />
                        <Route  path="/terms-conditions" element={<TermsCondition/>}  />
                        <Route  path="/q-n-a" element={<QnA/>}  />
                        <Route  path="/about" element={<About/>}  />
                        <Route  path="/rules" element={<Rules/>}  />
                        <Route  path="/tutorial" element={<Tutorial/>}  />
                        <Route  path="/common-problem" element={<FAQ/>}  />
                    </Routes >
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;