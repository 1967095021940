import React, {useEffect,useState } from 'react';
import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 
import { logs } from '../../Services/Utils';
 
// reactstrap components
import { 
  Button,
  Table ,
  item,
  CardBody,
  FormGroup,
  Form,
  Input, 
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

function CoinComponent({ item, coinData  }) { 
  
  const [lastPrice, setLastPrice] = useState(item.last);
  const [last24h, setLast24h] = useState(item.open24h);

  let last    = item.last;
  let open24h = item.open24h;

  useEffect(() => {   
    if(coinData.instId == item.instId){ 
      logs( coinData.instId +":"+ lastPrice +" to "+coinData.last)
      setLastPrice(coinData.last)
      setLast24h(coinData.open24h)
    } 
    // eslint-disable-next-line
  }, [coinData]); 



  const calculatePercentageChange = (last, open24h) => {
      if (!coinData) return null;

      let lastPrice = parseFloat(last);
      let openingPrice = parseFloat(open24h);

      if (isNaN(lastPrice) || isNaN(openingPrice) || openingPrice === 0) {
        return null;
      }

      const percentageChange = ((lastPrice - openingPrice) / openingPrice) * 100;
      return formatNumber(percentageChange);  // Round to two decimal places
  };

  const formatNumber = (number) => {
      // Convert to a number
      const num = Number(number);

      // If the number is negative, convert to positive before formatting
      const positiveNum = num < 0 ? Math.abs(num) : num;

      if (positiveNum < 0.0001) {
        // Convert to 4 decimal places
        const formattedPositiveNum = positiveNum.toFixed(4);

        // If the original number was negative, add the negative sign back
        return num < 0 ? `-${formattedPositiveNum}` : formattedPositiveNum;
      } else if (positiveNum < 0.005) {
        // Convert to 3 decimal places
        const formattedPositiveNum = positiveNum.toFixed(3);

        // If the original number was negative, add the negative sign back
        return num < 0 ? `-${formattedPositiveNum}` : formattedPositiveNum;
      } else {
        // Convert to 3 decimal places, force display even for rounded numbers
        const formattedPositiveNum = positiveNum.toFixed(3);

        // If the original number was negative, add the negative sign back
        return num < 0 ? `-${formattedPositiveNum}` : formattedPositiveNum;
      }
  };

  return ( 
      <tr  key={item.key}> 
        <td>
          <span className="coin-header">
            <span className="coin-icon">
              <img src={item.image} height={30}/>
            </span>
            <span className="coin-title">
              {item.title}
            </span>
          </span>
        </td>
        <td> 
            <span className="coin-price">
              {formatNumber(lastPrice)}
            </span> 
        </td>
        <td>
          <span className={`coin-change ${calculatePercentageChange(lastPrice, last24h) < 0 ? 'red' : 'green'}`}> 
            {calculatePercentageChange(lastPrice, last24h)}%
          </span>
        </td>
      </tr>  
  );
}

export default CoinComponent;
