
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
  
import '../Common.css'; 
import { Profile, Pages, Banner, PageVersion, BannerVersion, coinList, notice } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer'; 
import SliderCarousel from './SliderCarousel';       
import TopNav from '../HomeNav';    
import Marquee from "react-fast-marquee";
import VideoPlayer from '../VideoPlayer'; 
import CoinComponent from './CoinComponent';   
import io from 'socket.io-client';

import { useTranslation } from 'react-i18next'; 

import { 
    Button,
    Table , 
    CardBody,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";
 
let IO_URL = process.env.REACT_APP_IO_URL; 

const socket = io(IO_URL, {
    transports: ['websocket'],
});


export default function Home() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ notices, setNotices ] = useState("");   
    const [ coins, setCoins ] = useState([]);    
    const [coinData, setCoinData] = useState({});

    const handleShop = () => { 
        navigate('/shop');
    }; 

    useEffect(() => {   
        GetBanner();     
        GetCoin();     
        GetNotice();       
 
        socket.on('update_ticker', (data) => {  
            let tickerData = data.detail;
            tickerData = JSON.parse(tickerData); 
            setCoinData(tickerData);
        });

        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line
    }, []); 
   
    const shop = () => { 
        navigate('/shop');
    }; 
 
    
    const [items, setItems] = useState([]);

    const GetBanner = async e => {    
        Banner({
        }, CallbackBanner);  
    } 

    const CallbackBanner = (returnData) => {   
        if(returnData.code === 1){ 
            setItems(returnData.data.lists);  
        }
    } 
    
    const GetNotice = async e => {    
        notice({
        }, CallbackNotice);  
    } 

    const CallbackNotice = (returnData) => {   
        if(returnData.code === 1){ 
            setNotices(returnData.data.lists);  
        }
    } 
    
    const GetCoin = async e => {    
        coinList({
        }, CallbackCoin);  
    } 

    const CallbackCoin = (returnData) => {  

        var data = returnData.data;
        setCoins(data.lists) 
    } 
  
    const goto = (path) => { 
        navigate('/' + path);
    }; 
  
    return (
    <>
        <div className="page-wrapper home-wrapper">   
            <TopNav/>
      
            <SliderCarousel imageData={items} /> 
 
            {notices.length > 0 && (
            <div className="notices">
                <span className="notices-icon">
                    <i className="fa-solid fa-volume-high"></i>
                </span>
                <Marquee>
                    {notices.map((notice, index) => (
                        <div key={index} dangerouslySetInnerHTML={{ __html: notice.content }} />
                        ))}
                </Marquee>
            </div>
            )}

            <VideoPlayer />

            <div className="home-nav">  
                <div className="home-nav-item" onClick={() => goto("deposit")}> 
                    <img src={"/images/icon_recharge.png"} height={50} alt={t('recharge')}/>
                    <span>{t("Recharge")}</span>
                </div> 
                <div className="home-nav-item" onClick={() => goto("withdrawal")}> 
                    <img src={"/images/icon_withdraw.png"} height={50} alt={t('withdraw')}/>
                    <span>{t("Withdraw")}</span>
                </div> 
                <div className="home-nav-item" onClick={() => goto("team-report")}> 
                    <img src={"/images/icon_team.png"} height={50} alt={t('Team')}/>
                    <span>{t("Team")}</span>
                </div>  
                <div className="home-nav-item" onClick={() => goto("quantify")}> 
                    <img src={"/images/taskcenter.png"} height={50} alt={t('Mission')}/>
                    <span>{t("Mission")}</span>
                </div>  
                <div className="home-nav-item" onClick={() => goto("invite")}> 
                    <img src={"/images/icon_activity.png"} height={50} alt={t('Invite')}/>
                    <span>{t("Invite")}</span>
                </div>  
            </div> 
  

            <div className="coin-lists">   
                {coins !== null && (
                    <Table borderless>
                    <thead>
                        <tr> 
                            <th>
                            {t("Trading pair")}
                            </th>
                            <th>
                            {t("Latest price")}
                            </th>
                            <th>
                            {t("Quote change")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {coins.map(item => ( 
                        <CoinComponent item={item} key={item.id} coinData={coinData} />
                    ))}
                    </tbody>
                    </Table>
                )}
            </div> 

        </div>
        <Footer/>
    </>
    );
};