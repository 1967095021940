import React from 'react';
import { useTranslation } from 'react-i18next'; 
 
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 

const SliderCarousel = ({ imageData }) => {

    const { t } = useTranslation();
     
  
    const imageDataArray = Object.values(imageData);
        
    if (imageDataArray.length === 0) {
        return ( <></>);
    }
    
    const settings = {  
        infinite: true,
        centerPadding: "0",
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000, 
      };
  return ( 
      <div className="ff_wrapper">
        <div className="module-slider">
            <Slider {...settings}>
              
            {imageDataArray.map(item => (
              <div className="slider-item" key={item.id}>
                <div className="slider-item-info">
                  <div className="slider-item-image">
                      <img src={item.image} alt={`${item.title}`} width="100" /> 
                  </div>
                </div>
              </div> 
            ))} 
            </Slider>
        </div> 
      </div> 
  );
}

export default SliderCarousel;
