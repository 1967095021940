import React from 'react';  
import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 
import { 
  Button,
  Row ,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input, 
  InputGroupText,
  InputGroup,
  Container, 
} from "reactstrap";

function MiningComponent({ item, miningPurchase }) {
 
  const { t } = useTranslation();
  return ( 
      <div className="records_item"  key={item.id}>
        {item !== null ? (
          <>
            <h4>
              <img src={"/images/usdt.png"} height={40}/>
              <span>{item.title}</span>
            </h4>
            <Row>
                <Col>
                    <span className="item_title">{t("Limit")}</span>
                    <span className="item_value">{item.limit}</span>
                </Col>
                <Col>
                    <span className="item_title">{t("Daily rate of return")}</span>
                    <span className="item_value">{item.return_rate}%</span>
                </Col>
                <Col>
                    <span className="item_title">{t("Cycle")}</span>
                    <span className="item_value">{item.days} ({t("Sky")})</span>
                </Col>
            </Row> 


            {item.can_buy === true ? (
              item.is_new === true ? (
                <Button className="mt-3" onClick={() => miningPurchase(item.id)} color="primary">
                  {t('Go buy')}
                </Button>
              ) : (
                <Button className="mt-3" color="primary" disabled={true}>
                  {t('already buy')}
                </Button>
              )
            ) : (
              <Button className="mt-3" color="primary" disabled={true}>
                {t('Coming Soon')}
              </Button>
            )}

          </> 
        ):''}
      </div> 
  );
}

export default MiningComponent;
