import React from 'react'; 
import Button from '@mui/material/Button';
import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 

function TransactionLogComponent({ item }) {
 
  const { t } = useTranslation();
  return (
    <div>
        <div className="records_item"  key={item.id}>
          {item !== null ? (  
              <div className="transaction-row">
                <div className="transaction-info">
                  <h3 className={`type_${item.type}`}>{t(item.type)}</h3>
                  <p className="transaction-date">{item.datetime}</p>
                </div>
                <div className="transaction-value">
                  <span className={`badge text-bg-${item.class}`} >{item.amount}</span>
                </div>
              </div> 
          ):''} 
        </div>
    </div>
  );
} 
export default TransactionLogComponent;
