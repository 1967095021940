
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import { logs } from '../../Services/Utils';

import MiningComponent from './MiningComponent';  
import LoadingSpinner from '../LoadingSpinner';  
import { useTranslation } from 'react-i18next'; 
import { miningList, miningSummary, miningInfo, miningFaq } from '../../Services/User';  
import Footer from '../Layout/Footer';
import { 
    Button,
    Row ,
    Col,
    CardBody,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from "reactstrap";
  
export default function Mining() {  
    const { t } = useTranslation();
    const navigate = useNavigate();  
 
    const [loading, setLoading] = React.useState(false)   
    const [miningLists, setMiningLists] = useState([]); 
    const [balance, setBalance] = useState(0); 
    const [todayEarning, setTodayEarning] = useState(0); 
    const [cumulativeIncome, setCumulativeIncome] = useState(0); 
    const [orderEscrow, setOrderEscrow] = useState(0); 
    const [faq, setFaq] = useState([]); 
    const [open, setOpen] = useState('0');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }     
        getMinings();
        getMiningSummary();
        getMiningInfo();
        getMiningFaq();
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
   
    const getMinings = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {    
            let token   = tokenInfo;  
            miningList({ 
                token
            }, CallbackMining);  
        }
    } 
    
    const CallbackMining = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;    
            setMiningLists(lists);  
        } 
    } 

    const getMiningSummary = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {    
            let token   = tokenInfo;  
            miningSummary({ 
                token
            }, CallbackMiningSummary);  
        }
    } 
    
    const CallbackMiningSummary = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;   
            setBalance(lists.balance); 
            setOrderEscrow(lists.orderEscrow); 
            setTodayEarning(lists.todayEarning); 
            setCumulativeIncome(lists.cumulativeIncome);   
        } 
    } 
     
    const getMiningFaq = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {    
            let token   = tokenInfo;  
            miningFaq({ 
                token
            }, CallbackMiningFaq);  
        }
    } 
    
    const CallbackMiningFaq = (returnData) => {  
        if(returnData.code === 1)
        { 
            var lists = returnData.data;   
            setFaq(lists);  
            logs(lists);
        }  
    } 
     
    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    const getOrderInfo = (order_number) => {    
        navigate('/order/'+order_number);

        // let tokenInfo = localStorage.getItem('token');  
        // if(tokenInfo === null){ 
        //     navigate('/login');
        // } 
        // else {   
        //     let token = tokenInfo; 
        //     let oid   = order_number; 
        //     GetOrderDetail({
        //         oid,
        //         token
        //     }, CallbackOrderInfo);  
        // }
    } 
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

    const toggleOffcanvas = () => {
      setIsOffcanvasOpen(!isOffcanvasOpen);
    };
    
    const [ title, setTitle ] = useState("");   
    const [ content, setContent ] = useState("");   

    const getMiningInfo = async e => {     
        miningInfo({ 
        }, CallbackPage);   
    } 
    
    const CallbackPage = (returnData) => {  
        var data = returnData.data;
        setTitle(data.title);  
        setContent(data.content);   
    }  
        
    const goto = (path) => { 
        navigate('/' + path);
    }; 
  
    const miningPurchase = (id) => {    
        navigate('/mining-purchase/'+id); 
    } 
    
    return (
        <> 
            <div className="page-wrapper mining-wrapper">  
            {loading ? <LoadingSpinner /> : ''} 
            
                <div className="app_wrapper">
                    <div className="mining_summary"  style={{ backgroundImage: 'url(/images/quantify_top_inset.png)' }}>
                        <div className="top-header" >
                            <div className="inner-top-nav">
                                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                                <span>{t('Funds in custody')}</span>
                                <span></span>
                            </div>
                        </div>
                        
                        <div className="mining_info">
                            <h4>{balance} <span>USDT</span></h4>
                            <Row>
                                <Col>
                                    <span className="item_title">{t("Estimated earnings today")}</span>
                                    <span className="item_value">{todayEarning}</span>
                                </Col>
                                <Col>
                                    <span className="item_title">{t("Cumulative income")}</span>
                                    <span className="item_value">{cumulativeIncome}</span>
                                </Col>
                                <Col>
                                    <span className="item_title">{t("Orders in Escrow")}</span>
                                    <span className="item_value">{orderEscrow}</span>
                                </Col>
                            </Row> 
                            <span className="btn_illustrate"  onClick={toggleOffcanvas}>{t("Illustrate")}</span>
                                            
                            <Button className="mt-3" onClick={() => goto("mining-logs")} color="primary" outline>
                                {t('Order details')}
                            </Button>
                        </div>
                    </div>

                    <div className="mining_lists">
                    {miningLists !== null ? (
                        miningLists.map(item => ( 
                            <MiningComponent item={item} key={item.id} miningPurchase={miningPurchase} />
                        )) 
                    ) : (
                        <div className="info-alert">{t('No record available')}</div>
                    )}
                    </div>

                    {faq !== null && (
                    <div className="mining_questions">
                        <h3>{t("Common problem")}</h3>
                        <Accordion open={open || []} toggle={toggle}>
                        {faq.map(item => ( 
                            <AccordionItem key={item.id}>
                            <AccordionHeader targetId={item.id.toString()}>{item.title}</AccordionHeader>
                            <AccordionBody accordionId={item.id.toString()}> 
                                <div dangerouslySetInnerHTML={{ __html: item.content }} /> 
                            </AccordionBody>
                            </AccordionItem> 
                        ))}
                        </Accordion>
                    </div>
                    )} 
                </div>  
            </div>  
 
        <Offcanvas isOpen={isOffcanvasOpen} toggle={toggleOffcanvas} 
    direction="bottom"> 
          <OffcanvasBody> 
            <h4>{title}</h4>
            <div dangerouslySetInnerHTML={{ __html: content }} /> 
          </OffcanvasBody>
        </Offcanvas>
 
        <Footer/>
        </>
    );
};