import React, {useEffect,useState } from 'react';

import { useTranslation } from 'react-i18next'; 
import { logs } from '../../Services/Utils';
 
import Slider from 'react-slick'; 

const VipCarousel = ({ imageData }) => {

    const { t } = useTranslation();
    const [title, setTitle] = useState("");   
    const [currentSlide, setCurrentSlide] = useState(0);
    
    const [vipId, setVipId] = useState(0);
    const [limit, setLimit] = useState(0);
    const [directVip, setDirectVip] = useState(0);
    const [directCount, setDirectCount] = useState(0);
    const [workingHour, setWorkingHour] = useState(0);
    const [condition, setCondition] = useState(0);
    const [balanceMin, setBalanceMin] = useState(0);
    const [balanceMax, setBalanceMax] = useState(0);
    const [quanCount, setQuanCount] = useState(0);
    const [promoReward, setPromoReward] = useState(0);
    const [returnRange, setReturnRange] = useState(0);
    const [balanceRange, setBalanceRange] = useState("");
 
    const imageDataArray = Object.values(imageData);
        
    const updateTitleAndContent = (index) => { 
      
      setWorkingHour(t("Working hours California, USA: 00:00- 23:59"))
      setCondition(t("VIP upgrade requires new users to accumulate 100 USDT Or more and bind their email addresses to be considered a valid inviter"))

        let datas = imageData[index];  
        if(datas){

          setTitle(datas.title) 
          setVipId(datas.id)
          setLimit(datas.limit)
          setDirectVip(datas.direct_vip)
          setDirectCount(datas.direc_count)
          setBalanceMin(datas.balance_min)
          setBalanceMax(datas.balance_max)
          setBalanceRange(datas.balance_range)
          setQuanCount(datas.quan_count)
          setPromoReward(datas.promo_reward)
          setReturnRange(datas.return_range)  
        } else { 
          logs("invalid index="+index)
        }
        
      logs("index="+index)
      logs(datas)
    };
 
    if (imageDataArray.length === 0) {
        return ( <></>);
    } 
    
    const settings = {  
        infinite: false,
        centerPadding: "0",
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 2000, 
        afterChange: (index) => {
          setCurrentSlide(index);
          updateTitleAndContent(index);  
          logs("index="+index)
        },
        onInit: () => {
          setCurrentSlide(0);
          updateTitleAndContent(0);  
          logs("index="+0)
        },
    };
      
  return ( 
      <div className="ff_wrapper">
        <div className="module-slider">
            <Slider {...settings}>
              
            {imageDataArray.map(item => (
              <div className="slider-item" key={item.id}>
                <div className="slider-item-info">
                  <div className="slider-item-image">
                      <img src={`images/v${item.id}.png`} alt={`${item.title}`} width="100" /> 
                  </div>
                </div>
              </div> 
            ))} 
            </Slider>
        </div> 
        {currentSlide >= 0 && ( 
          <div className="card-wrapper"> 
            <div className="mb-3"> 
              <h4>VIP{vipId} {t("Upgrade conditions")}</h4>
              <div className="list-row">
                <span className="list-row-title">{t("Balance")}</span>
                <span className="list-row-value">{balanceRange}</span>
              </div> 
              {directCount > 0 && (
                <div className="list-row">
                  <span className="list-row-title">{t("Promote members")}{`(VIP${directVip})`}</span>
                  <span className="list-row-value">>{directCount}</span>
                </div> 
                )}  
                <h4>{t("Important Tips")}</h4>
                <p>{workingHour}</p>
                <p>{condition}</p>
            </div>
            <div className="mb-3"> 
              <h4>VIP{vipId} {t("Equity")}</h4>
              <div className="list-row">
                <span className="list-row-title">{t("Minimum quantification quantity")}</span>
                <span className="list-row-value">{balanceMin}</span>
              </div> 
              <div className="list-row">
                <span className="list-row-title">{t("Maximum Quantity")}</span>
                <span className="list-row-value">{balanceMax}</span>
              </div> 
              <div className="list-row">
                <span className="list-row-title">{t("Quantification times per day")}</span>
                <span className="list-row-value">{quanCount} {t("times")}</span>
              </div> 
              <div className="list-row">
                <span className="list-row-title">{t("Promotion reward")}</span>
                <span className="list-row-value">{promoReward} USDT</span>
              </div> 
              <div className="list-row">
                <span className="list-row-title">{t("Daily rate of return")}</span>
                <span className="list-row-value">{returnRange}</span>
              </div> 
            </div>
          </div>
          )}
      </div> 
  );
}

export default VipCarousel;
