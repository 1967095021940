import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";   
import { FormControlLabel, Checkbox, Typography } from '@mui/material'; 
 
import TextField from '@mui/material/TextField'; 
import LoadingSpinner from '../LoadingSpinner';   
import TopNav from '../GuessNav';   
import FormControl from '@mui/material/FormControl'; 

import {UserLogin, GetSupport, countryCode, logs } from '../../Services/User'
import useToken from '../App/useToken';  
 
// reactstrap components
import { 
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";

  
import { useTranslation } from 'react-i18next';  
import { Notyf } from "notyf"; 
const notyf = new Notyf({duration: 5000}); 
    
export default function Login() {   
 
    const { t } = useTranslation();
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);  
    const [supportUrl, setSupportUrl] = useState('');
    const [tgUrl, setTgUrl] = useState('');
    const [allowedCountries, setAllowedCountries] = useState(["US"]);
  
    const handleRegister = () => { 
        navigate('/register');
    }; 

    const { token, setToken } = useToken();
    const navigate = useNavigate();

    useEffect(() => { 
        if(token){ 
            navigate('/home');
        } 
        GetCountryCode();
  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetCountryCode = async e => {    
        countryCode({}, CallbackCountryCode);  
    } 
    
    const CallbackCountryCode = (returnData) => {  
        if(returnData.code === 1)
        {  
            var data = returnData.data;  
            setAllowedCountries(data);  
        }   
    }  
    const [username, setUsername] = useState('');   
    const [password, setPassword] = useState('');    
  
    const handleLogin = async e => { 
        e.preventDefault();      
        setLoading(true);  
    
        UserLogin({ 
            username,
            password
        }, CallbackLogin);
    } 
 
    const CallbackLogin = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var token = returnData.data;
            setToken(token.token); 
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', token.token);  
            localStorage.setItem('username', token.username);  
            localStorage.setItem('inviteCode', token.inviteCode);  
            localStorage.setItem('isLogged', true);  

             
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }
 
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    }     

    useEffect(() => {
        setIsDisabled(username === '' || password === ''  ); 
    }, [username, password]);

    const handlePhoneChange = (newPhone) => {
        setUsername(newPhone)
    }

    const handleTermsClick = (e) => {
        e.preventDefault();
        navigate('/terms-conditions');
    };

    const handlePrivacyClick = (e) => {
        e.preventDefault();
        navigate('/privacy-policy');
    };
    

    const support = (e) => { 
        e.preventDefault();
        window.open(supportUrl, '_blank'); 
    };
 
    const handleGoBack = () => {
        navigate('/');
    };
        
    const [ showPs, setShowPs] = useState(false);
    const [ pwd, setPwd ] = useState("");  
    const toggleShowPs = () => {
        setShowPs((showPs) => !showPs); 
    };

    return( 
        <div className="login-page"> 
            {loading ? <LoadingSpinner /> : ''} 
             
            <div className="page-wrapper account-wrapper">  
           
                <TopNav path={"/"} title={t('Login please')} />
                
                <div className="form-wrapper">   
                    <h4>{t('User login')}</h4>
                    <form className="loginBox" onSubmit={handleLogin} >   
                 
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-regular fa-user"></i>
                        </InputGroupText> 
                          <Input 
                            placeholder={t("username")}  
                            name="username"
                            value={username}
                            onChange={(e)=>inputChangeHandler(setUsername, e)}    
                          />
                        </InputGroup>
                    </FormGroup>
                             
                    <FormGroup>
                        <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <i className="fa-solid fa-lock"></i>
                            </InputGroupText> 
                          <Input
                            placeholder={t("Password")}
                            type={showPs ? 'text' : 'password'}
                            autoComplete="off"
                            value={password}
                            onChange={(e)=>inputChangeHandler(setPassword, e)}    
                          /> 
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                  <i className="fas fa-eye"></i>
                              ): (
                                <i className="fas fa-eye-slash"></i> 
                            )}
                            </InputGroupText> 
                        </InputGroup>
                    </FormGroup> 
                        
                    <div className="form-action">  
                        <Button type="submit" className="btn btn-primary" disabled={isDisabled} >{t('Login')}</Button> 
                    </div>   

                    <div className="nav-reg">  
                        <span>
                            {t('Don’t have an account?')} <span className="nav-link"  size="large" onClick={handleRegister}>{t("Register")}</span> 
                        </span>
                        <span className="nav-forgot" onClick={support} style={{ cursor: 'pointer', color: 'blue' }}>
                        {t("Forgot Password?")}
                        </span>
                    </div>     
                </form> 
            </div>  
        </div>
    </div> 
    );
} 