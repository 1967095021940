
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import Box from '@mui/material/Box';  
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress'; 

import { privacyPolicy } from '../../Services/User';  
  
import Typography from '@mui/material/Typography';  
import { useTranslation } from 'react-i18next';

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function PrivacyPolicy() {  

    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ title, setTitle ] = useState("");   
    const [ content, setContent ] = useState("");   
    
    useEffect(() => {   
        GetPrivacyPolicy();   
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const GetPrivacyPolicy = async e => {     
        privacyPolicy({ 
        }, CallbackPage);   
    } 
    
    const CallbackPage = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setTitle(data.title);  
            setContent(data.content);   
        } 
    }  

  return (
    <>
     <div className="content-page">  
        <div className="page-wrapper info-wrapper">  
            <div className="top-header">
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Language settings')}</span>
                    <span></span>
                </div>
            </div>
            
            <div className="app_wrapper"> 
                <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: content }} /> 
                </div>
            </div>
   
        </div> 
    </div> 
    </>
  );
};