
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
  
import { useTranslation } from 'react-i18next';
import { faqPage } from '../../Services/User';   

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function FAQ() {  

    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ langcode, setLangcode ] = useState("en");  
    const [ title, setTitle ] = useState("");   
    const [ content, setContent ] = useState("");   
    
    useEffect(() => {   
        const storedLang = localStorage.getItem('lang'); 
        if (storedLang) {
            setLangcode(storedLang);
        } 
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    useEffect(() => {   
        GetPage();   
    }, []); 

    const GetPage = async e => {     
        faqPage({ 
        }, CallbackPage);   
    } 
    
    const CallbackPage = (returnData) => {  
        if(returnData.code === 1)
        { 
            var data = returnData.data;
            setTitle(data.title);  
            setContent(data.content);  
        }  
    }
     
  return (
    <>
    <div className="content-page">  
    <div className="page-wrapper info-wrapper">  
        <div className="top-header">
            <div className="inner-top-nav">
                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                <span>{title}</span>
                <span></span>
            </div>
        </div>
        
            <div className="app_wrapper"> 
                <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: content }} /> 
                </div>
            </div>

        </div> 
    </div> 
    </>
  );
};