
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'  
import { experimentalStyled as styled } from '@mui/material/styles'; 
import Button from '@mui/material/Button';
  
import { quantLogs } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';
import QuantComponent from './QuantComponent';   
import LoadingSpinner from '../LoadingSpinner';   
import { useTranslation } from 'react-i18next'; 
import InnerNav from '../InnerNav';   

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 
 
export default function QuantLog() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [iswaiting, setIswaiting] = useState(false); 
    const [iscomplete, setIscomplete] = useState(false); 
    const [isall, setIsall] = useState(true); 
    const [list, setList] = useState([]);
    const [orderInfo, setOrderInfo] = useState(false);
    const [orderDetail, setOrderDetail] = useState([]);
    const [orderError, setOrderError] = useState(false);
    const [orderErrorMessage, setOrderErrorMessage] = useState([]);
    const [postorderBtn, setPostorderBtn] = useState(false);// idle, submitting, allocated, waiting
    const [countdown2, setCountdown2] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }  
        getQuantLogs(); 
        // eslint-disable-next-line
    }, [currentPage]); 
  
    const getQuantLogs = async e => {    
        
        setCurrentPage(currentPage)
        
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {   
            quantLogs({ 
                token
            }, CallbackQuantLogs);  
        }
    } 
     
    const CallbackQuantLogs = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;  
            setList(lists.data);
            setCurrentPage(lists.current_page);
            setTotalPages(lists.last_page); 
        } 
    } 
  
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
          setCurrentPage(newPage);
        }
    };
    
    return (
    <>
        <div className="page-wrapper record-wrapper"> 
        
        <InnerNav title={t('Quantitative record')}/>
    
            <div className="quant-logs">

                <div className="record-lists"> 
                {list.length > 0 ? (
                    list.map(item => ( 
                        <QuantComponent item={item} key={item.id}/>
                    )) 
                ) : (
                    <div className="records_tabs"> 
                        <div className="text-center">{t('No record available')}</div> 
                </div> 
                )}
                </div>  

                 
            {list.length > 0 ? (           
                <div className="paginator">
                        <span
                            className={`paginator-link ${currentPage === 1 ? 'disabled' : ''}`}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-chevron-left"></i>
                        </span>
                        <span>{t('Page')} {currentPage} / {totalPages}</span>
                        <span 
                            className={`paginator-link ${currentPage === totalPages ? 'disabled' : ''}`} 
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </div>
                ): null}

            </div> 
        </div>
        <Footer/>
    </>
    );
};