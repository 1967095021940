import CryptoJS from "crypto-js"; 
import md5 from 'md5';
import json  from 'json-keys-sort'; 
import { useNavigate } from 'react-router-dom'   
 
export const doSomethingWithInput = (theInput) => {
   //Do something with the input
   return theInput;
};
 
const secretKEY = process.env.REACT_APP_AESKEY;  
const secretIV = process.env.REACT_APP_AESIV;  
const signKey = process.env.REACT_APP_SIGNKEY;  
 
export const encryptData = (postJson, suffix) => {
    let IV1 = secretIV+suffix
    let IV = CryptoJS.enc.Utf8.parse(IV1);
    let key1 = CryptoJS.enc.Utf8.parse(secretKEY); 
    var postdata = JSON.stringify(postJson)  
    let encrypted = CryptoJS.AES.encrypt(postdata, key1, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString(); 
};

export const decryptData = (postdata, suffix) => {
    let IV1 = secretIV+suffix
    let IV = CryptoJS.enc.Utf8.parse(IV1);
    let key1 = CryptoJS.enc.Utf8.parse(secretKEY);  
    let decrypted = CryptoJS.AES.decrypt(postdata, key1, { iv: IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }); 
    return decrypted.toString(CryptoJS.enc.Utf8)
};

export const genSign = (postJson) => { 
    var sign = ""; 
    postJson = json.sort(postJson, true) 
    Object.entries(postJson).forEach((entry) => {
        const [key, value] = entry; 
        sign += key+"="+value+"&"
    });
    
    sign = sign + signKey;  
    return md5(sign)
} 

export function logs(message) {
    if(process.env.REACT_APP_ENV === 'dev')
    {
        console.log(message);
    } 
} 