
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
import { Profile, GetSupport } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';  
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';  

import { useTranslation } from 'react-i18next';

// reactstrap components
import { 
    Button,
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
    ListGroup,
    ListGroupItem,
    Input, 
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";
   
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 
export default function My() {  
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();   
    const [username, setUsername] = useState(0); 
    const [tel, setTel] = useState(0); 
    const [invitecode, setInvitecode] = useState(0);  
    const [lang, setLang] = useState("en");   
     
    const [vip, setVip] = useState(0);  
    const [balance, setBalance] = useState(0);  
    const [quantitaiveBenefit, setQuantitaiveBenefit] = useState(0);  
    const [totalRevenue, setTotalRevenue] = useState(0);  
    const [todayCommission, setTodayCommission] = useState(0);  
    const [monthCommission, setMonthCommission] = useState(0);  
    const [totalCommission, setTotalCommission] = useState(0);  
    const [qtime, setQtime] = useState("");  
    const [summmaryLevel1, setSummmaryLevel1] = useState("");  
    const [summmaryLevel2, setSummmaryLevel2] = useState("");  
    const [summmaryLevel3, setSummmaryLevel3] = useState("");  

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }   
        UserProfile(); 
        let local_lang = localStorage.getItem('lang');  

        if(local_lang !== null){
          setLang(local_lang);
        }
        // eslint-disable-next-line
    }, []); 
   
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setUsername(data.username);
            setBalance(data.balance);
            setInvitecode(data.invite_code);
            setVip(data.vip);

            setQuantitaiveBenefit(data.quantitaiveBenefit);
            setTotalRevenue(data.totalRevenue);
            setTodayCommission(data.todayCommission);
            setMonthCommission(data.monthCommission);
            setTotalCommission(data.totalCommission);
            
            let quantizationCount = data.quanBalance + "/" + data.quanTotal
            setQtime(quantizationCount);
            
            let summmaryLevel1_txt = data.level1Count + "(" + data.level1Active + ")"
            setSummmaryLevel1(summmaryLevel1_txt);
            
            let summmaryLevel2_txt = data.level2Count + "(" + data.level2Active + ")"
            setSummmaryLevel2(summmaryLevel2_txt);
            
            let summmaryLevel3_txt = data.level3Count + "(" + data.level3Active + ")"
            setSummmaryLevel3(summmaryLevel3_txt);
         
        } 
    } 
 
    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
 
    const handleBank = (e) => { 
        e.preventDefault();
        navigate('/bank');
    }; 
    const handlePassword = (e) => { 
        e.preventDefault();
        navigate('/password');
    }; 
    const handlePasswordWithdraw = (e) => { 
        e.preventDefault();
        navigate('/withdraw-password');
    }; 
    
    const handleLanguage = (e) => { 
        e.preventDefault();
        navigate('/language');
    }; 

    const handleRealname = (e) => { 
        e.preventDefault();
        navigate('/realname');
    }; 

    const handleInvite = (e) => { 
        e.preventDefault();
        navigate('/invite');
    }; 
    const handleWithdrawalHistory = (e) => { 
        e.preventDefault();
        navigate('/withdrawal-history');
    }; 
    const handleDepositHistory = (e) => { 
        e.preventDefault();
        navigate('/deposit-history');
    }; 
    const handleService = (e) => { 
        e.preventDefault();
        navigate('/service');
    }; 
    const handleProfile = (e) => { 
        e.preventDefault();
        navigate('/profile');
    }; 

    const handleDeposit = (e) => { 
        e.preventDefault();
        navigate('/deposit');
        // navigate('/deposits/trc20');
    }; 
    const handleWithdrawal = (e) => { 
        e.preventDefault();
        navigate('/withdrawal');
    }; 

    const handleRecord = (e) => { 
        e.preventDefault();
        navigate('/record');
    }; 

    const handleAccount = (e) => { 
        e.preventDefault();
        navigate('/transaction-logs');
    }; 
    const handleMsg = (e) => { 
        e.preventDefault();
        navigate('/msg');
    }; 
    const handleReport = (e) => { 
        e.preventDefault();
        navigate('/team-report');
    }; 
  
    const handlePolicy = (e) => { 
        e.preventDefault();
        navigate('/privacy-policy');
    }; 
    const handleCopy = (e) => { 
        e.preventDefault();
        navigate('/terms-conditions');
    }; 
    const handleQNA = (e) => { 
        e.preventDefault();
        navigate('/q-n-a');
    }; 
    const handleAbout = (e) => { 
        e.preventDefault();
        navigate('/about');
    }; 

    const divStyle = { 
        backgroundImage: 'url(/images/banner.png)',
        backgroundPosition: 'top center',    
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    }; 
    const cardStyle = { 
        backgroundImage: 'url(/images/card.png)',
        backgroundPosition: 'top center',    
        backgroundSize: 'contain',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat'
    }; 

    
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleLogout = () => {
        setOpenConfirm(true);
    };
  
    const handleCloseReject = () => {
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false); 
        logout();
    };
    const logout = () => {
        localStorage.clear();
        navigate('/login');
    };
    
    const languageOptions = [
        { value: 'en', label: t('English') },
        { value: 'ar', label: t('Arabic') },
        { value: 'zh', label: t('Traditional Chinese') },
        { value: 'pt', label: t('Portuguese') },
        { value: 'es', label: t('Spanish') },
        { value: 'fr', label: t('French') },
        { value: 'de', label: t('German') },
        { value: 'th', label: t('Thai') },
        { value: 'hi', label: t('Hindi') },
        { value: 'ru', label: t('Russian') },
        { value: 'bn', label: t('Bengali') },
        { value: 'ja', label: t('Japanese') },
        { value: 'ko', label: t('Korean') },
    ];
  
    const goto = (path) => { 
        navigate('/' + path);
    }; 

    function copy(code){
    // Create a new text area element
    const textArea = document.createElement('textarea');
    textArea.value = code;

    // Append the text area to the DOM
    document.body.appendChild(textArea);

    // Select and copy the text from the text area
    textArea.select();
    document.execCommand('copy');

    // Clean up
    document.body.removeChild(textArea);

    notyf.success(t("UUID have copy to clipboard"));  
    }
 
  return (
    <>
        <div className="page-wrapper my-wrapper">  

            <div className="module app_wrapper"> 
                <div className="home-header"> 
                    <div className="home-info-col"> 
                        <h4>{username}</h4>
                        <h5>{t('UUID')}:{invitecode}
                          <span className="drawer-copy-btn"  onClick={() => copy(invitecode)}><i className="fa-regular fa-copy"></i></span></h5>
                    </div>
                    <div className="home-info-col"> 
                        <span className="btn-profile" onClick={handleProfile} ><i className="fa-regular fa-pen-to-square"></i></span>
                    </div>
                    <div className="home-info-col avatar"> 
                        <img src={"/images/head.png"} height={100} alt={t('Profile')}/>
                    </div>
                </div>
            </div>
        
            <div className="card mt-3">  
                <div className="card-body"> 
                    <Row>
                        <Col>
                            <span className="item_value">{balance}</span>
                            <span className="item_title">{t("Total balance")}</span>
                        </Col>
                        <Col>
                            <span className="item_value">{quantitaiveBenefit}</span>
                            <span className="item_title">{t("Quantitative benefit")}</span>
                        </Col>
                        <Col>
                            <span className="item_value">{totalRevenue}</span>
                            <span className="item_title">{t("Total revenue")}</span>
                        </Col>
                    </Row> 
                    <Row className="mt-3">
                        <Col>
                            <span className="item_value">{todayCommission}</span>
                            <span className="item_title">{t("Commission today")}</span>
                        </Col>
                        <Col>
                            <span className="item_value">{monthCommission}</span>
                            <span className="item_title">{t("Commission this month")}</span>
                        </Col>
                        <Col>
                            <span className="item_value">{totalCommission}</span>
                            <span className="item_title">{t("Total commission")}</span>
                        </Col>
                    </Row> 
                </div>  
            </div>  
            
            <div className="card-vip">
                <div className="card-vip-header">
                    <div className="vip-info">
                        <h4>{t("Current level")}</h4>
                        <h3>
                            {t("VIP")}{vip}
                            {vip > 0 && (
                                <img src={"images/mine_vip_crown.png"} height={30}/>
                            )}
                        </h3>
                    </div>
                    <div className="vip-info">
                        <h5>{t("Quantization times")}({qtime})</h5>
                    </div>
                </div>  
                <div className="card-vip-info">
                    <Row>
                        <Col>
                            <span className="item_value">{summmaryLevel1}</span>
                            <span className="item_title">{t("Level 1")}</span>
                        </Col>
                        <Col>
                            <span className="item_value">{summmaryLevel2}</span>
                            <span className="item_title">{t("Level 2")}</span>
                        </Col>
                        <Col>
                            <span className="item_value">{summmaryLevel3}</span>
                            <span className="item_title">{t("Level 3")}</span>
                        </Col>
                    </Row> 
                </div>  
            </div>  
  
            <div className="module">
            
                <ListGroup className="mamber-nav">
                    <ListGroupItem onClick={() => goto("about")}>
                        <span  className="btn-title">
                        <i className="fa-solid fa-circle-info"></i>{t("Company Profile")}
                        </span>
                        <span  className="btn-back">
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                    <ListGroupItem onClick={() => goto("rules")}>
                        <span  className="btn-title">
                        <i className="fa-regular fa-message"></i>
                        {t("Platform rules")}
                        </span>
                        <span  className="btn-back">
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                    <ListGroupItem onClick={() => goto("common-problem")}>
                        <span  className="btn-title">
                        <i className="fa-regular fa-circle-question"></i>
                        {t("Common problem")}
                        </span>
                        <span  className="btn-action">
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                    <ListGroupItem onClick={() => goto("my")}>
                        <span  className="btn-title">
                        <i className="fa-solid fa-shield"></i>
                        {t("Security center")}
                        </span>
                        <span  className="btn-action">
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                    <ListGroupItem onClick={() => goto("transaction-logs")}>
                        <span  className="btn-title">
                        <i className="fa-solid fa-clipboard-list"></i>
                        {t("Fund details")}
                        </span>
                        <span  className="btn-action">
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                    <ListGroupItem onClick={() => goto("invite")}>
                        <span  className="btn-title">
                        <i className="fa-solid fa-user-plus"></i>
                        {t("Invite friends")}
                        </span>
                        <span  className="btn-action">
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                    <ListGroupItem onClick={() => goto("team-report")}>
                        <span  className="btn-title">
                        <i className="fa-solid fa-people-group"></i>
                        {t("My Team")}
                        </span>
                        <span  className="btn-action">
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                    <ListGroupItem onClick={() => goto("tutorial")}>
                        <span  className="btn-title">
                        <i className="fa-solid fa-book-open"></i>
                        {t("Quantization Tutorial")}
                        </span>
                        <span  className="btn-action">
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                    <ListGroupItem onClick={() => goto("language")}>
                        <span  className="btn-title">
                        <i className="fa-solid fa-globe"></i>
                        {t("Language settings")}
                        </span>
                        <span  className="btn-action">
                        {languageOptions[lang]}
                        <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </ListGroupItem>
                </ListGroup>
        
            </div>

 
            <div className="module">
                <div className="app_wrapper mt-5"> 
                    <a href="#" className="btn btn-primary btn-block" onClick={handleLogout}> 
                        <span>{t("Log Out")}</span>
                    </a>
                </div>
            </div>
   
        <Footer/>
        </div> 

        
  
        <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        > 
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {t('Are you sure to exit??')}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseReject}>{t('Cancel')}</Button>
            <Button onClick={handleCloseConfirm} autoFocus>
            {t('Confirm')}
            </Button>
            </DialogActions>
        </Dialog>
    </>
  );
};