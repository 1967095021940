import { useNavigate } from "react-router-dom";   

import { useTranslation } from 'react-i18next'; 

const GuessNav = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

 
  const handleGoBack = () => {
      navigate(props.path);
  };
  const handleSupport = () => { 
      let supportUrl = "https://chat.ichatlink.net/widget/standalone.html?eid=19b9e4719db8b0d75a55297a86c35ca6&language=en";
      window.open(supportUrl, '_blank'); 
  };
  const handleLanguage = () => {
      navigate("/language");
  };
      
  return (
    <>
       <div className="top-header">
        <div className="inner-top-nav">
            <span className="btn-back" onClick={handleGoBack}>
                <i className="fa-solid fa-arrow-left"></i> 
            </span> 
            <span></span>
            <span className="top-nav-right">
              <span className="btn-back" onClick={handleSupport}>
                <i className="fa-solid fa-headset"></i>
              </span>
              <span className="btn-back" onClick={handleLanguage}>
                <i class="fa-solid fa-globe"></i>
              </span>  
            </span>
        </div>
        <h1>{props.title}</h1>
      </div>
    </> 
  );
}

export default GuessNav;