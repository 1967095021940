import React, {useEffect,useState } from 'react';
import { useNavigate } from "react-router-dom";   
import { useTranslation } from 'react-i18next'; 

 
// reactstrap components
import { 
  Button,
  Offcanvas ,
  OffcanvasHeader,
  OffcanvasBody,
  ListGroup,
  ListGroupItem,
  Input, 
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

const HomeNav = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

 
  const handleGoBack = () => {
      navigate(props.path);
  };
  const handleSupport = () => { 
      let supportUrl = "https://chat.ichatlink.net/widget/standalone.html?eid=19b9e4719db8b0d75a55297a86c35ca6&language=en";
      window.open(supportUrl, '_blank'); 
  };
  const handleContact = () => {
      navigate("/");
  };
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const [username, setUsername] = useState('');   
  const [inviteCode, setInviteCode] = useState('');    
  const [isLogged, setIsLogged] = useState(false);    
  const [lang, setLang] = useState("en");    

  useEffect(() => {  
    let local_username = localStorage.getItem('username');  
    let local_inviteCode = localStorage.getItem('inviteCode');  
    let local_isLogged = localStorage.getItem('isLogged');  
    let local_lang = localStorage.getItem('lang');  

    if(local_username !== null){
      setUsername(local_username);
    }

    if(local_inviteCode !== null){
      setInviteCode(local_inviteCode);
    }

    if(local_isLogged !== null){
      setIsLogged(true);
    }

    if(local_lang !== null){
      setLang(local_lang);
    }
    // eslint-disable-next-line
}, []);

const languageOptions = [
  { value: 'en', label: t('English') },
  { value: 'ar', label: t('Arabic') },
  { value: 'zh', label: t('Traditional Chinese') },
  { value: 'pt', label: t('Portuguese') },
  { value: 'es', label: t('Spanish') },
  { value: 'fr', label: t('French') },
  { value: 'de', label: t('German') },
  { value: 'th', label: t('Thai') },
  { value: 'hi', label: t('Hindi') },
  { value: 'ru', label: t('Russian') },
  { value: 'bn', label: t('Bengali') },
  { value: 'ja', label: t('Japanese') },
  { value: 'ko', label: t('Korean') },
];


const goto = (path) => { 
  navigate('/' + path);
}; 

function copy(code){
   // Create a new text area element
   const textArea = document.createElement('textarea');
   textArea.value = code;

   // Append the text area to the DOM
   document.body.appendChild(textArea);

   // Select and copy the text from the text area
   textArea.select();
   document.execCommand('copy');

   // Clean up
   document.body.removeChild(textArea);

   notyf.success(t("UUID have copy to clipboard"));  
}

const handleDeposit = (e) => { 
  e.preventDefault();
  navigate('/deposit');
  // navigate('/deposits/trc20');
}; 
const handleWithdrawal = (e) => { 
  e.preventDefault();
  navigate('/withdrawal');
}; 

  return (
    <>
      <div className="top-header">
        <div className="inner-top-nav">
            <span className="btn-back" onClick={toggleOffcanvas}>
              <i className="fa-solid fa-user"></i>
            </span> 
            <span></span>
            <span className="top-nav-right">
              <span className="btn-back" onClick={handleSupport}>
                <i className="fa-solid fa-headset"></i>
              </span>
              <span className="btn-back" onClick={handleContact}>
                <i className="fa-regular fa-envelope"></i>
              </span>  
            </span>
        </div>  
        <Offcanvas isOpen={isOffcanvasOpen} toggle={toggleOffcanvas}> 
          <OffcanvasBody>
            <div className="drawer-panel">
              <div className="drawer-header">
                <div className="drawer-info">
                  <span  className="drawer-avatar">
                    <img src={"/images/0.png"} height={60}/>
                  </span>
                  <span  className="drawer-title">
                    {isLogged ? ( 
                      <>
                        <h4>{username}</h4>
                        <h5>
                          <span>UUID: {inviteCode}</span>
                          <span className="drawer-copy-btn"  onClick={() => copy(inviteCode)}><i className="fa-regular fa-copy"></i></span>
                        </h5>
                      </>
                    ) : (
                      <h3>{t("Please login")}</h3>
                    )}
                  </span>
                </div>
                <span  className="drawer-action">
                  <span  className="btn-back">
                    <i className="fa-solid fa-chevron-right"></i>
                  </span>
                </span>
              </div>

              
              <div className="wallet-action"> 
                  <div className="btn btn-recharge" onClick={handleDeposit}   > 
                      <img src={"/images/mycenter-pop-recharge.png"} height={35} />
                      <span>{t('Recharge')}</span>
                  </div> 
                  <div className="btn btn-withdraw" onClick={handleWithdrawal}  > 
                      <img src={"/images/mycenter-pop-cashout.png"} height={35} />
                      <span>{t('Withdraw')}</span>
                  </div> 
              </div>
              
            </div>
            <ListGroup className="mamber-nav">
              <ListGroupItem onClick={() => goto("about")}>
                <span  className="btn-title">
                <i className="fa-solid fa-circle-info"></i>{t("Company Profile")}
                </span>
                <span  className="btn-back">
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
              <ListGroupItem onClick={() => goto("rules")}>
                <span  className="btn-title">
                <i className="fa-regular fa-message"></i>
                  {t("Platform rules")}
                </span>
                <span  className="btn-back">
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
              <ListGroupItem onClick={() => goto("common-problem")}>
                <span  className="btn-title">
                <i className="fa-regular fa-circle-question"></i>
                  {t("Common problem")}
                </span>
                <span  className="btn-action">
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
              <ListGroupItem onClick={() => goto("my")}>
                <span  className="btn-title">
                <i className="fa-solid fa-shield"></i>
                {t("Security center")}
                </span>
                <span  className="btn-action">
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
              <ListGroupItem onClick={() => goto("transaction-logs")}>
                <span  className="btn-title">
                <i className="fa-solid fa-clipboard-list"></i>
                {t("Fund details")}
                </span>
                <span  className="btn-action">
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
              <ListGroupItem onClick={() => goto("invite")}>
                <span  className="btn-title">
                <i className="fa-solid fa-user-plus"></i>
                {t("Invite friends")}
                </span>
                <span  className="btn-action">
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
              <ListGroupItem onClick={() => goto("team-report")}>
                <span  className="btn-title">
                <i className="fa-solid fa-people-group"></i>
                {t("My Team")}
                </span>
                <span  className="btn-action">
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
              <ListGroupItem onClick={() => goto("tutorial")}>
                <span  className="btn-title">
                <i className="fa-solid fa-book-open"></i>
                {t("Quantization Tutorial")}
                </span>
                <span  className="btn-action">
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
              <ListGroupItem onClick={() => goto("language")}>
                <span  className="btn-title">
                <i className="fa-solid fa-globe"></i>
                {t("Language settings")}
                </span>
                <span  className="btn-action">
                  {languageOptions[lang]}
                  <i className="fa-solid fa-chevron-right"></i>
                </span>
              </ListGroupItem>
            </ListGroup>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </> 
  );
}

export default HomeNav;