
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
import { transactionHistory} from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';
import TransactionLogComponent from './TransactionLogComponent'; 
import { useTranslation } from 'react-i18next'; 

import InnerNav from '../InnerNav';   
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 


export default function TransactionLogs() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [alllog, setAlllog] = useState(false); 
    const [rechargelog, setRechargelog] = useState(false); 
    const [withdrawlog, setWithdrawlog] = useState(false);  
    const [list, setList] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [activeTab, setActiveTab] = useState('detail');
    // const [currentPage, setCurrentPage] = useState(1); // Current page number
  
    // Common function to fetch data based on data type and page number
    const fetchData = (dataType, page) => {
      // Set the active tab
      setActiveTab(dataType);
      setCurrentPage(page)
  
      // Call the respective function based on the data type
    //   if (dataType === 'alllog') {
    //     getAllLogs(page);
    //   } else if (dataType === 'withdrawal') {
    //     getWithdrawLogs(page);
    //   } else if (dataType === 'deposit') {
    //     getRechargeLogs(page);
    //   }
        getLogs(dataType, page);
    };

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }  
        fetchData(activeTab, currentPage)
        // eslint-disable-next-line
    }, [ currentPage]); 
 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    // const getAllLogs = (page) => {    
    //     let tokenInfo = localStorage.getItem('token');  
    //     if(tokenInfo === null){ 
    //         navigate('/login');
    //     } 
    //     else {  
    //         setAlllog(true); 
    //         setRechargelog(false); 
    //         setWithdrawlog(false); 
    //         let token   = tokenInfo;   
    //         transactionHistory({
    //             page,
    //             token
    //         }, CallbackLogs);  
    //     }
    // } 
    
    // const getRechargeLogs = (page) => {    
    //     let tokenInfo = localStorage.getItem('token');  
    //     if(tokenInfo === null){ 
    //         navigate('/login');
    //     } 
    //     else {  
    //         setAlllog(false); 
    //         setRechargelog(true); 
    //         setWithdrawlog(false);  
    //         let token   = tokenInfo; 
    //         let type    = 2;  
    //         transactionHistory({
    //             page,
    //             type,
    //             token
    //         }, CallbackLogs);  
    //     }
    // } 
    
    // const getWithdrawLogs = (page) => {    
    //     let tokenInfo = localStorage.getItem('token');  
    //     if(tokenInfo === null){ 
    //         navigate('/login');
    //     } 
    //     else {  
    //         setAlllog(false); 
    //         setRechargelog(false); 
    //         setWithdrawlog(true);  
    //         let token   = tokenInfo; 
    //         let type    = 1;  
    //         transactionHistory({
    //             page,
    //             type,
    //             token
    //         }, CallbackLogs);  
    //     }
    // } 
    
    // const CallbackLogs = (returnData) => {  
    //     if(returnData.code !== 1)
    //     { 
    //         if(returnData.code === 3001 || returnData.code === 3002 )
    //         { 
    //             localStorage.removeItem('token'); 
    //             localStorage.removeItem('userdata'); 
    //             navigate('/login');
    //         }  
    //     } 
    //     else{  
    //         var lists = returnData.data;   
    //         setList(lists.data);
    //         setCurrentPage(lists.current_page);
    //         setTotalPages(lists.last_page); 
    //     } 
    // } 
    
    const getLogs = (dataType, page) => {    
        let token = localStorage.getItem('token');  
        if(token === null){ 
            navigate('/login');
        } 
        else {    
            let type    = dataType;  
            setList([]);
            transactionHistory({
                page,
                type,
                token
            }, CallbackLogs);  
        }
    } 
    
    const CallbackLogs = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;   
            setList(lists.data);
            setCurrentPage(lists.current_page);
            setTotalPages(lists.last_page); 
        } 
    } 
    
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
          setCurrentPage(newPage);
        }
    };
    
    return (
    <>
        <div className="page-wrapper record-wrapper"> 
            
            <InnerNav title={t('Funding Details')}/>
        
            <div className="inner-tab-nav">
                <span
                    className={`nav-tab ${activeTab === 'detail' ? 'active' : ''}`}
                    onClick={() => fetchData('detail', 1)}
                >
                    {t("Details")}
                </span>
                <span
                    className={`nav-tab ${activeTab === 'deposit' ? 'active' : ''}`}
                    onClick={() => fetchData('deposit', 1)}
                >
                    {t("Deposit")}
                </span>
                <span
                    className={`nav-tab ${activeTab === 'withdraw' ? 'active' : ''}`}
                    onClick={() => fetchData('withdraw', 1)}
                >
                    {t("Withdrawal")}
                </span>
            </div>

            <div className="record-lists"> 
            {list.length > 0 ? (
                list.map(item => ( 
                    <TransactionLogComponent item={item} key={item.id}/>
                )) 
            ) : (
                <div className="records_tabs"> 
                    <div className="text-center">{t('No record available')}</div> 
              </div> 
            )}
            </div> 
 
                                    
            {list.length > 0 ? (           
                <div className="paginator">
                        <span
                            className={`paginator-link ${currentPage === 1 ? 'disabled' : ''}`}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-chevron-left"></i>
                        </span>
                        <span>{t('Page')} {currentPage} / {totalPages}</span>
                        <span 
                            className={`paginator-link ${currentPage === totalPages ? 'disabled' : ''}`} 
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <i className="fa-solid fa-chevron-right"></i>
                        </span>
                    </div>
                ): null}
 
        </div>
        <Footer/>
    </>
    );
};