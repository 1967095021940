
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
import { useTranslation } from 'react-i18next'; 
import { teamReport } from '../../Services/User';  
import { logs } from '../../Services/Utils';
import { Picker, Field } from 'react-vant'  
  
import Footer from '../Layout/Footer';
import DownlineComponent from './DownlineComponent';  
import InnerNav from '../InnerNav';   

import { 
    Button,
    Row ,
    Col,
    CardBody,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from "reactstrap";

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function TeamReport() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [list, setList] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); 
    const [lvl, setLvl] = useState(1); 

    const [selfTotalMember, setSelfTotalMember] = useState(0);
    const [selfValidMember, setSelfValidMember] = useState(0);
    const [selfTeamIncome, setSelfTeamIncome] = useState(0);
    const [selfTeamSize, setSelfTeamSize] = useState(0);

    const [level1TeamCommission, setLevel1TeamCommission] = useState(0);
    const [level1TotalMember, setLevel1TotalMember] = useState(0);
    const [level1ValidMember, setLevel1ValidMember] = useState(0);
    const [level1TeamIncome, setLevel1TeamIncome] = useState(0);

    const [level2TeamCommission, setLevel2TeamCommission] = useState(0);
    const [level2TotalMember, setLevel2TotalMember] = useState(0);
    const [level2ValidMember, setLevel2ValidMember] = useState(0);
    const [level2TeamIncome, setLevel2TeamIncome] = useState(0);
    
    const [level3TeamCommission, setLevel3TeamCommission] = useState(0);
    const [level3TotalMember, setLevel3TotalMember] = useState(0);
    const [level3ValidMember, setLevel3ValidMember] = useState(0);
    const [level3TeamIncome, setLevel3TeamIncome] = useState(0);
 
    useEffect(() => {  
        getTeamReport()
        // eslint-disable-next-line
    }, [ ]); 
   
    const [teamDaysValue, setTeamDaysValue] = useState(0); 
    const [selfDaysValue, setSelfDaysValue] = useState(0); 
    const [teamDaysTitle, setTeamDaysTitle] = useState("All"); 
    const [selfDaysTitle, setSelfDaysTitle] = useState("All"); 

    const getTeamReport = () => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {   
            let token       = tokenInfo;   
            let team_days   = teamDaysValue;   
            let self_days   = selfDaysValue;   
            teamReport({ 
                token,
                team_days,
                self_days
            }, CallbackTeamReport);  
        }
    } 
    
    const CallbackTeamReport = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            
            setSelfTotalMember(data.summary.registered_member)
            setSelfValidMember(data.summary.valid_member)
            setSelfTeamIncome(data.summary.team_income)
            setSelfTeamSize(data.summary.registered_member)

            setLevel1TeamCommission(data.levelTree.level1.team_commission)
            setLevel1TotalMember(data.levelTree.level1.registered_member)
            setLevel1ValidMember(data.levelTree.level1.valid_member)
            setLevel1TeamIncome(data.levelTree.level1.team_income)

            setLevel2TeamCommission(data.levelTree.level2.team_commission)
            setLevel2TotalMember(data.levelTree.level2.registered_member)
            setLevel2ValidMember(data.levelTree.level2.valid_member)
            setLevel2TeamIncome(data.levelTree.level2.team_income)
            
            setLevel3TeamCommission(data.levelTree.level3.team_commission)
            setLevel3TotalMember(data.levelTree.level3.registered_member)
            setLevel3ValidMember(data.levelTree.level3.valid_member)
            setLevel3TeamIncome(data.levelTree.level3.team_income) 
        } 
    } 
       
    const columns = [
        {'title':t('All'), 'value':0},
        {'title':t('Today'), 'value':1},
        {'title':t('Last 3 days'), 'value':3},
        {'title':t('Last 7 days'), 'value':7},
        {'title':t('Last 15 days'), 'value':15},
        {'title':t('Nearly 30 days'), 'value':30},
    ]
    
    const filterSelfDay = (dtitle, dday) => {   
        setSelfDaysValue(dday)
        setSelfDaysTitle(dtitle) 
    }
    const filterTeamDay = (dtitle, dday) => {   
        setTeamDaysValue(dday)
        setTeamDaysTitle(dtitle) 
    }

    const goto = (path) => { 
      navigate('/' + path);
    }; 
    

    return (
    <>
        <div className="page-wrapper record-wrapper"> 
            
            <InnerNav title={t('My team')}/>
   
            <div className="app_wrapper"> 
                <div className="card-wrapper">
                    <div className="card-wrapper-header">
                        <h4>{t("Team data overview")}</h4>
                        <span className="filter">
                            <Picker     
                            popup={{
                                round: true,
                            }}
                            value={selfDaysValue} 
                            columns={columns}
                            columnsFieldNames={{
                                text: 'title', value: 'value'
                            }}
                            onChange={(val, selectRow, index) => {   
                                if(selectRow !== undefined){
                                    filterSelfDay(selectRow.title, selectRow.value);
                                }
                            }}
                            onConfirm={(val, selectRow, index) => { 
                                logs("SelfDay")
                                logs(selfDaysValue)
                                logs(selfDaysTitle) 
                                getTeamReport()
                            }}
                            confirmButtonText={t("confirm")}
                            cancelButtonText={t("cancel")}
                            placeholder={t("please select")}
                            >
                            {(val, _, actions) => { 
                                return (
                                <Field
                                    readOnly
                                    clickable 
                                    value={val || ''}
                                    onClick={() => actions.open()}
                                >{ _.title}</Field>
                                )
                            }}
                            </Picker>
                        </span>
                    </div>
                    <div className="card-wrapper-body"> 
                        <Row>
                            <Col>
                                <span className="item_title">{t("Registered member")}</span>
                                <span className="item_value">{selfTotalMember}</span>
                            </Col>
                            <Col>
                                <span className="item_title">{t("Valid member")}</span>
                                <span className="item_value">{selfValidMember}</span>
                            </Col>
                            <Col>
                                <span className="item_title">{t("Team income")}</span>
                                <span className="item_value">{selfTeamIncome}</span>
                            </Col>
                        </Row> 
                    </div> 
                    <div className="card-wrapper-footer"> 
                        <span>{t("Cumulative team size: ")}{selfTeamSize}</span>
                        <span className="nav-link" onClick={() => goto("team-list")}>{t("View team list")}</span>
                    </div> 
                </div>  

                <div className="card-wrapper mt-3">
                    <div className="card-wrapper-header">
                        <h4>{t("The last three generations of data")}</h4>
                        <span className="filter">
                            <Picker     
                            popup={{
                                round: true,
                            }}
                            value={teamDaysTitle} 
                            columns={columns}
                            columnsFieldNames={{
                                text: 'title', value: 'value'
                            }}
                            onChange={(val, selectRow, index) => {  
                                if(selectRow !== undefined){
                                    filterTeamDay(selectRow.title, selectRow.value);
                                }
                            }}
                            onConfirm={(val, selectRow, index) => {  
                                logs("TeamDay")
                                logs(teamDaysValue)
                                logs(teamDaysTitle) 
                                getTeamReport()
                            }}
                            confirmButtonText={t("confirm")}
                            cancelButtonText={t("cancel")}
                            placeholder={t("please select")}
                            >
                            {(val, _, actions) => {
                                return (
                                <Field
                                    readOnly
                                    clickable 
                                    value={val ? val : ''}
                                    onClick={() => actions.open()}
                                />
                                )
                            }}
                            </Picker>
                        </span>
                    </div>
                    <div className="card-wrapper-body"> 
                        <div className="team-row"> 
                            <h5>{t("Generation data")}</h5>
                            <Row>
                                <Col>
                                    <span className="item_value">{level1TeamCommission}</span>
                                    <span className="item_title">{t("Team commission")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level1TotalMember}</span>
                                    <span className="item_title">{t("Registered member")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level1ValidMember}</span>
                                    <span className="item_title">{t("Valid member")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level1TeamIncome}</span>
                                    <span className="item_title">{t("Team income")}</span>
                                </Col>
                            </Row> 
                        </div> 
                        <div className="team-row"> 
                            <h5>{t("Second generation data")}</h5>
                            <Row>
                                <Col>
                                    <span className="item_value">{level2TeamCommission}</span>
                                    <span className="item_title">{t("Team commission")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level2TotalMember}</span>
                                    <span className="item_title">{t("Registered member")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level2ValidMember}</span>
                                    <span className="item_title">{t("Valid member")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level2TeamIncome}</span>
                                    <span className="item_title">{t("Team income")}</span>
                                </Col>
                            </Row> 
                        </div> 
                        <div className="team-row"> 
                            <h5>{t("Three generations of data")}</h5>
                            <Row>
                                <Col>
                                    <span className="item_value">{level3TeamCommission}</span>
                                    <span className="item_title">{t("Team commission")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level3TotalMember}</span>
                                    <span className="item_title">{t("Registered member")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level3ValidMember}</span>
                                    <span className="item_title">{t("Valid member")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{level3TeamIncome}</span>
                                    <span className="item_title">{t("Team income")}</span>
                                </Col>
                            </Row> 
                        </div> 
                    </div>  
                </div>  
    
            </div>
        </div>
        <Footer/>
    </>
    );
};