
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import Button from '@mui/material/Button'; 
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { UpdatetLang, Profile } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner';  
import { useTranslation } from "react-i18next";
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Language() {  
    const navigate = useNavigate();  
    const { i18n } = useTranslation(); 
    const { t }    = useTranslation();
    
    const [loading, setLoading] = useState(false)  
    const [lang, setLang] = useState('en'); 
 
    useEffect(() => {  
        
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
    const handleUpdateLang = async e => {    
        e.preventDefault();       
            
        i18n.changeLanguage(lang); 
        localStorage.setItem('lang', lang);  
    } 
  
    const handleChange = (event) => {
        setLang(event.target.value);  
        i18n.changeLanguage(event.target.value); 
        localStorage.setItem('lang', event.target.value);  
    };
    
    return (
        <>
            <div className="content-page"> 
                {loading ? <LoadingSpinner /> : ''} 
                <div className="page-wrapper account-wrapper">  
                    <div className="top-header">
                        <div className="inner-top-nav">
                            <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                            <span>{t('Language settings')}</span>
                            <span></span>
                        </div>
                    </div>
                    <div className="form-wrapper">  
                        <form className="form" onSubmit={handleUpdateLang} >   
                            <FormControl> 
                                <RadioGroup
                                    aria-labelledby="language"
                                    name="language"
                                    value={lang}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="en" control={<Radio />} label={t('English')} />
                                    <FormControlLabel value="es" control={<Radio />} label={t('Spanish')} />
                                    <FormControlLabel value="vn" control={<Radio />} label={t('Vietnamese')} />
                                    <FormControlLabel value="zh" control={<Radio />} label={t('Traditional Chinese')} /> 
                                    <FormControlLabel value="cn" control={<Radio />} label={t('Simplified Chinese')} /> 
                                </RadioGroup>
                            </FormControl>
                             
                        </form> 
                    </div>
                </div> 
            </div> 
        </>
    );
};