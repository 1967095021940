import React from 'react';   
import { useTranslation } from 'react-i18next'; 

import { 
  Button,
  Row ,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input, 
  InputGroupText,
  InputGroup,
  Container, 
} from "reactstrap";

function QuantComponent({ item, getOrderInfo }) {
 
  const { t } = useTranslation();
  return (
    <div>
        <div className="records_item"  key={item.id}>
          {item !== null && ( 
            <div className="card mb-3">
              <div className="card-body"> 
              <div className="quant-log">
                <div className="quant-log-header"> 
                  <h4>
                    <img src={item.coin_image} height={30}/>
                    <span>{item.coin_title}</span>
                  </h4>
                  <span className="link-status">Success <i className="fa-solid fa-chevron-right"></i></span>
                </div>
                <Row>
                    <Col className="left">
                        <span className="item_title">{t("Execute position")}</span>
                        <span className="item_value">{item.position}</span>
                    </Col>
                    <Col className="center">
                        <span className="item_title">{t("Buy platform")}</span>
                        <span className="item_value">{item.platform_buy}</span>
                    </Col>
                    <Col className="right">
                        <span className="item_title">{t("Purchase price")}</span>
                        <span className="item_value">{item.price_purchase}</span>
                    </Col>
                </Row> 
                <Row>
                    <Col className="left">
                        <span className="item_title">{t("Time")}</span>
                        <span className="item_value">{item.datetime}</span>
                    </Col>
                    <Col className="center">
                        <span className="item_title">{t("Sales platform")}</span>
                        <span className="item_value">{item.platform_sell}</span>
                    </Col>
                    <Col className="right">
                        <span className="item_title">{t("Selling price")}</span>
                        <span className="item_value">{item.price_sell}</span>
                    </Col>
                </Row> 
                <Row>
                    <Col className="left">
                        <span className="item_title">{t("Service charge")}</span>
                        <span className="item_value">{item.service_charge}</span>
                    </Col>
                    <Col className="center">
                        <span className="item_title">{t("Profit")}</span>
                        <span className="item_value">{item.profit}</span>
                    </Col> 
                    <Col className="right"> 
                    </Col> 
                </Row>  
              </div> 
              </div>
            </div>
          )} 
        </div>
    </div>
  );
}

export default QuantComponent;
