
import React, {useEffect,useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom'  
import { experimentalStyled as styled } from '@mui/material/styles';  
import Dialog from '@mui/material/Dialog';

import { vipLists, Profile, quant } from '../../Services/User';  
import useToken from '../App/useToken';  
 
import { useTranslation } from 'react-i18next'; 
import InnerNav from '../InnerNav';   
import Footer from '../Layout/Footer';
import { logs } from '../../Services/Utils';
import VipCarousel from './VipCarousel';      
 
import { 
    Button,
    Row ,
    Col,
    Spinner,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container, 
    Offcanvas ,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap";
  
import { Notyf } from "notyf";

const notyf = new Notyf({duration: 5000}); 
 
const StyledText = styled('text')(({ theme }) => ({
    fill: "#1A1C1E",
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 31,
    fontWeight: 'bold',
  }));
  
const StyledText2 = styled('text')(({ theme }) => ({
    fill: "#6C7278",
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 11,
    width:'100px'
  }));

export default function Quantify() {  
 
    const { tier } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();   
    const [vips, setVips] = useState(0);  
    const [balance, setBalance] = useState(0);  
    const [quantitaiveBenefit, setQuantitaiveBenefit] = useState(0);  
    const [totalRevenue, setTotalRevenue] = useState(0);  
    const [todayCommission, setTodayCommission] = useState(0);  
    const [monthCommission, setMonthCommission] = useState(0);  
    const [totalCommission, setTotalCommission] = useState(0);  
    const [qtime, setQtime] = useState("0/0");  
    const [summmaryLevel1, setSummmaryLevel1] = useState("");  
    const [summmaryLevel2, setSummmaryLevel2] = useState("");  
    const [summmaryLevel3, setSummmaryLevel3] = useState("");  

    const [buttonText, setButtonText] = useState('Start quantification');
    const [processlogs, setProcesslogs] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false); 
    const [isLoading, setIsLoading] = useState(false); 
 
    const [quantToday, setQuantToday] = useState(0);  
    const [quantMonth, setQuantMonth] = useState(0);  
    const [quanBalance, setQuanBalance] = useState(0);  
    const [quanTotal, setQuanTotal] = useState(0);  
    const [vip, setVip] = useState(0);  
    const [buttonShowText, setButtonShowText] = useState(t("Submit"));  
    const [buttonOffText, setButtonOffText] = useState(t("Quantifying..."));  

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();     
        getVipLists();     
    }, []); 

    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;  
            setBalance(data.balance); 

            setQuantitaiveBenefit(data.quantitaiveBenefit); 
            setTodayCommission(data.todayCommission);
            setMonthCommission(data.monthCommission);
            setTotalCommission(data.totalCommission);
            setQuantToday(data.quantToday);
            setQuantMonth(data.quantMonth);
            setQuanBalance(data.quanBalance);
            setQuanTotal(data.quanTotal);
            setVip(data.vip);
             
            logs("CallbackProfile")
            logs("quanBalance="+data.quanBalance)
            logs("quanTotal="+data.quanTotal)
            
            buttonTextFunc(isProcessing, data.quanBalance, data.quanTotal);
            
            if(data.quanBalance > 0){
                setIsProcessing(false);
            } 
        } 
    } 

    const getVipLists = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            vipLists({
                token
            }, CallbackGetVipLists);  
        }
    } 
    
    const CallbackGetVipLists = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setVips(data); 
        } 
    } 

    const sleep = (seconds) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));
 
    const startProcessing = async () => {
        notyf.success(t("Quantization is enabled, please wait patiently for a few minutes")); 

        setProcesslogs([]);
        setIsProcessing(true);

        await appendLogWithSpinner(t('Quantitative robot started successfully...'));
        await sleep(getRandomSeconds());
     
        // Step 3: Connecting
        await appendLogWithSpinner(t('Scanning the market...'));
        await sleep(getRandomSeconds());
        
        // Step 4: Scanning the market
        await appendLogWithSpinner(t('Order matching...'));
        await sleep(getRandomSeconds());

        // Step 5: Order matching
        await appendLogWithSpinner(t('Price matching...'));
        await sleep(getRandomSeconds());

        // Step 6: Price matching
        await appendLogWithSpinner(t('Platform sell order...'));
        await sleep(getRandomSeconds());

        // Step 7: Send API request to submitOrder
        await appendLogWithSpinner(t('The system is settling'));
        await sleep(getRandomSeconds());
 
        try {
            const result = await QuantOrder(); // Your API request 
        } catch (error) {
            appendLog(t('Error submitting order'));
        }
    };
    
    const getRandomSeconds = () => Math.floor(Math.random() * 3) + 1;

    const appendLog = (text) => {
        setProcesslogs((prevLogs) => [...prevLogs, text]);
    };

    const [count, setCount] = useState(0);

    const appendLogWithSpinner = async (text) => {
        setIsLoading(true); // Enable the spinner
        // Append a log entry with a loading indicator
        setProcesslogs((prevLogs) => [...prevLogs, { text, isLoading: true }]);

        setCount((prevCount) => prevCount + 1); 
         
        // Remove the loading indicator after the delay
        setProcesslogs((prevLogs) =>
          prevLogs.map((log, index) =>
            index === prevLogs.length - 1 ? { text, isLoading: false } : log 
          )
        );
        setIsLoading(false); // Disable the spinner after the delay
      };
  
    const QuantOrder = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            quant({
                token
            }, CallbackQuant);  
        }
    } 
    
    const CallbackQuant = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  else { 
                setCount((prevCount) => prevCount + 1); 
                appendLogWithSpinner(t('Error submitting order'));
                appendLogWithSpinner(returnData.message);
                setIsProcessing(false);  
            }
        } 
        else{  
            var data = returnData.data;  

            appendLog(t('Income is being distributed'));
            appendLog(t('Quantification completed')); 
    
            setCount((prevCount) => prevCount + 1); 
            
            // Step 9: Complete processing
            setIsProcessing(false);  
            
            notyf.success(t("Quantization complete")); 

            setProcesslogs([])

            UserProfile();
        } 
    } 
  
    const goto = (path) => { 
        navigate('/' + path);
    }; 
      
    useEffect(() => {  
          
        buttonTextFunc(isProcessing, quanBalance, quanTotal);

    }, [quanBalance, quanTotal, isProcessing]); 
 
    const buttonTextFunc = (isProcessing, quanBalance, quanTotal) => {
        let buttonText1 =  t('Start quantification')+" ("+quanBalance + "/" + quanTotal+")";
        setButtonShowText(buttonText1);

        logs("isProcessing="+isProcessing)
        logs("buttonOffText="+buttonOffText)
        logs("buttonShowText="+buttonShowText)

        if(isProcessing){ 
            setButtonText(buttonOffText);
        } else { 
            setButtonText(buttonShowText);
        } 
    }
       
    return (
        <>
            <div className="page-wrapper shop-wrapper">  
      
                <InnerNav title={t('Quantitative trading')}/>
         
                <div className="module quant_wrapper"> 
                    <div className="accoun-balance" style={{ backgroundImage: 'url(/images/quantify_top_inset.png)' }}> 
                        <div className="account-balance-info"> 
                            <div className="account-balance-header"> 
                                <h4>{t("Quantifiable funds")}<small>&nbsp;(VIP: {vip})</small></h4>
                                <h1>{balance} <span>USDT</span></h1>
                            </div> 
                        </div>  
                        <div className="quant-action">  
                            <Button className="mt-3" onClick={() => goto("quant-logs")} color="primary" outline>
                                {t('Quantitative')}
                            </Button>
                            <Button className="mt-3" onClick={() => goto("team-report")} color="primary" outline>
                                {t('Team income')}
                            </Button>
                        </div>  

                    </div>

                    <div className="card mt-3">  
                        <div className="card-body"> 
                            <Row>
                                <Col>
                                    <span className="item_value">{quantToday}</span>
                                    <span className="item_title">{t("Today's Earnings")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{quantMonth}</span>
                                    <span className="item_title">{t("Earnings this month")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{quantitaiveBenefit}</span>
                                    <span className="item_title">{t("Quantify total revenue")}</span>
                                </Col>
                            </Row> 
                            <Row className="mt-3">
                                <Col>
                                    <span className="item_value">{todayCommission}</span>
                                    <span className="item_title">{t("Today's team")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{monthCommission}</span>
                                    <span className="item_title">{t("Team of the month")}</span>
                                </Col>
                                <Col>
                                    <span className="item_value">{totalCommission}</span>
                                    <span className="item_title">{t("Total team revenue")}</span>
                                </Col>
                            </Row>

                            <div className="order-action">   
                                <button  type="submit" className="btn btn-primary"   onClick={isProcessing ? null : startProcessing} disabled={isProcessing}>
                                    {buttonText}
                                </button> 
                            </div>  

                            <div className="order-action">   
                                {processlogs.map((log, index) => (
                                <div key={index}>
                                    {log.text} {count-1 == index && <Spinner size="sm"/>}  
                                </div>
                                    ))}
                            </div>  
                        </div>  
                    </div>   
                </div> 

                <div className="module vip_lists">
                    <div className="vip_list_header">
                        <h4>
                            <img src="images/liubianxing.png" height={30} />
                            <span>{t('VIP level')}</span>
                        </h4>
                                    
                        <VipCarousel imageData={vips} /> 
                    </div>
                </div>
            </div>  

            <Footer/>
        </>
    );
};