
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'  
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';  

import { GetBank, SetBank, Profile,  country, countryVersion, countryCode } from '../../Services/User';  

import LoadingSpinner from '../LoadingSpinner'; 
import { useTranslation } from 'react-i18next'; 
import InnerNav from '../InnerNav';   


import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Bank() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    const [ canupdate, setCanupdate ]   = useState(false);   
    const [ type, setType ]     = useState('TRC20');   
    const [ address, setAddress ]   = useState('');      
    const [ realname, setRealname ]     = useState("");   
    const [ tel, setTel ]               = useState("");    
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserBank();    
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserBank = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            GetBank({
                token
            }, CallbackGetBank);  
        }
    } 
     
    const CallbackGetBank = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            } else { 
                setType("trc20");   
                setCanupdate(true);
                UserProfile();
            }
        } 
        else{  
            var data = returnData.data; 
            if(data !== null){
                setRealname(data.real_name);  
                setType(data.type);  
                setAddress(data.address);   
                setCanupdate(false);
            } else {
                setType("trc20");   
                setCanupdate(true);
                UserProfile();
            }
        } 
    } 

    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setRealname(data.real_name);  
        } 
    } 
 
    const handleBindBank = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');  
        SetBank({ 
            token,
            type,
            address, 
            realname
        }, CallbackBindBank);
    } 
 
    const CallbackBindBank = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data; 
            notyf.success(t(returnData.message));  
            UserBank();  
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
     
 
    useEffect(() => {
        setIsDisabled(  type  === '' || realname === '' || address === ''); 
    }, [type, realname, address]);

    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper account-wrapper">  
              
                <InnerNav title={t('Bind Wallet Address')}/>

                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleBindBank} >   
                        <div className="form-group">  
                            <TextField 
                            size="small" 
                            id="realname" 
                            value={realname} 
                            label={t('Real Name')} 
                            variant="standard"  
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e)=>inputChangeHandler(setRealname, e)}   disabled={!canupdate} />
                        </div>  
                 
                        
                        <div className="form-group">  
                            <TextField 
                            size="small" 
                            id="usernamaccountname" 
                            value={type} 
                            label={t('Wallet Type')} 
                            variant="standard"  
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e)=>inputChangeHandler(setType, e)}   disabled/>
                        </div>
                        <div className="form-group">  
                            <TextField 
                            size="small" 
                            id="address" 
                            value={address} 
                            label={t('Wallet Address')} 
                            variant="standard"  
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e)=>inputChangeHandler(setAddress, e)}   disabled={!canupdate} />
                        </div> 
                         
                        <div className="form-action">  
                            {canupdate ? (
                                <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t('Submit')}</Button> 
                            ) : (
                                <div className="form-alert">{t('Please contact admin for update')}</div>
                            )}
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};