import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";   
import { FormControlLabel, Checkbox, Typography } from '@mui/material'; 
import TextField from '@mui/material/TextField'; 
import LoadingSpinner from '../LoadingSpinner'; 
import TopNav from '../GuessNav';   

import { UserRegister, countryCode} from '../../Services/User'
import { logs } from '../../Services/Utils';
import useToken from '../App/useToken';   
import FormControl from '@mui/material/FormControl'; 

import { Notyf } from "notyf";  
import { useTranslation } from 'react-i18next'; 
  
 
// reactstrap components
import { 
    Button,
    Table ,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input, 
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";


const notyf = new Notyf({duration: 5000}); 
    
export default function Register() {    
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    const { inviteCode } = useParams();
    const [invite_code, setInvite_code] = useState(inviteCode || '');
    const isReadOnly = inviteCode !== undefined; 
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [username, setUsername] = useState('');   
    const [email, setEmail] = useState('');    
    const [pwdConfirm, setPwdConfirm] = useState('');   
    const [password, setPassword] = useState('');     
      
    const login = () => { 
        navigate('/login');
    }; 

    const { token, setToken } = useToken();
    const navigate = useNavigate();

    useEffect(() => { 
        if(token){ 
            navigate('/home');
        }  
        GetCountryCode(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetCountryCode = async e => {    
        countryCode({}, CallbackCountryCode);  
    } 
    
    const CallbackCountryCode = (returnData) => {  
        if(returnData.code === 1)
        {  
            var data = returnData.data;  
            setAllowedCountries(data); 
            logs(data)
        }  
    } 
      
    const handleRegister = async e => { 
        e.preventDefault(); 
 
        if(password !==  pwdConfirm)
        {
            notyf.error(t("Invalid password confirmation")); 
            return false;
        }
        e.preventDefault();      
        setLoading(true); 
        UserRegister({
            username,
            email,
            password,
            pwdConfirm,
            invite_code
        }, Callback);
    } 
 
    const Callback = (returnData) => {    
        setLoading(false);
        var code = returnData.code; 
        if(code === 1)
        { 
            var token = returnData.data;
            setToken(token.token); 
            localStorage.removeItem('token'); 
            localStorage.removeItem('userdata'); 
            localStorage.setItem('token', token.token);  
            navigate('/home');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    }
 
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
        
    const [ showPs, setShowPs] = useState(false); 
    const [ showPsConfirm, setShowPsConfirm] = useState(false); 
    const toggleShowPs = () => {
        setShowPs((showPs) => !showPs); 
    };
    const toggleShowPsConfirm = () => {
        setShowPsConfirm((showPs) => !showPs); 
    };

    const handleLogin = () => { 
        navigate('/login');
    }; 

    const handleTermsClick = (e) => {
        e.preventDefault();
        navigate('/terms-conditions');
    };

    const handlePrivacyClick = (e) => {
        e.preventDefault();
        navigate('/privacy-policy');
    };
    

    useEffect(() => {
        setIsDisabled(username === '' || email === '' || password === '' || pwdConfirm === '' || invite_code === ''); 
    }, [username, password, email, pwdConfirm, invite_code]);
  
    return( 
        <div className="login-page"> 
            {loading ? <LoadingSpinner /> : ''} 
             
            <div className="page-wrapper account-wrapper">  

                <TopNav path={"/login"} title={t('Create Account')}/>
    
                <div className="form-wrapper">   
                    <form className="loginBox" onSubmit={handleRegister} >   
                    
                    
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-regular fa-user"></i>
                        </InputGroupText> 
                          <Input 
                            placeholder={t("Please enter user name")}  
                            name="username" 
                            onChange={(e)=>inputChangeHandler(setUsername, e)}    
                          />
                        </InputGroup>
                    </FormGroup>
                              
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-solid fa-envelope"></i>
                        </InputGroupText> 
                          <Input 
                            placeholder={t("Please input your email")}  
                            type="email" 
                            name="email" 
                            onChange={(e)=>inputChangeHandler(setEmail, e)}    
                          />
                        </InputGroup>
                    </FormGroup>
                     
                    <FormGroup>
                        <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <i className="fa-solid fa-lock"></i>
                            </InputGroupText> 
                          <Input
                            placeholder={t("Please enter password")}
                            type={showPs ? 'text' : 'password'}
                            autoComplete="off"
                            value={password}
                            onChange={(e)=>inputChangeHandler(setPassword, e)}    
                          /> 
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                  <i className="fas fa-eye"></i>
                              ): (
                                <i className="fas fa-eye-slash"></i> 
                            )}
                            </InputGroupText> 
                        </InputGroup>
                    </FormGroup> 
                             
                    <FormGroup>
                        <InputGroup className="input-group-alternative"> 
                            <InputGroupText>
                                <i className="fa-solid fa-lock"></i>
                            </InputGroupText> 
                          <Input
                            placeholder={t("Enter password again")}
                            type={showPsConfirm ? 'text' : 'password'}
                            autoComplete="off" 
                            onChange={(e)=>inputChangeHandler(setPwdConfirm, e)}    
                          /> 
                            <InputGroupText  onClick={toggleShowPsConfirm}>
                            {showPs ?(
                                  <i className="fas fa-eye"></i>
                              ): (
                                <i className="fas fa-eye-slash"></i> 
                            )}
                            </InputGroupText> 
                        </InputGroup>
                    </FormGroup> 
                 
                    <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative"> 
                        <InputGroupText>
                            <i className="fa-solid fa-envelope-open-text"></i>
                        </InputGroupText> 
                          <Input 
                            placeholder={t("Invitation code")}   
                            id="referralCode" 
                            onChange={(e)=>inputChangeHandler(setInvite_code, e)}    
                          />
                        </InputGroup>
                    </FormGroup> 
        
                        <div className="form-action">  
                            <Button type="submit" className="btn btn-primary" disabled={isDisabled} >{t('Register')}</Button> 
                        </div>   

                        <div className="nav-reg">  
                            <span>
                                {t('Already have an account?')} <span className="nav-link"  size="large" onClick={handleLogin}>{t("Log in now")}</span> 
                            </span> 
                        </div>  


                        <div className="form-group input-terms">  
                                <p>
                                {t("By registering you agree")}{' '}
                                <span onClick={handleTermsClick} style={{ cursor: 'pointer', color: 'blue' }}>
                                    {t("Terms of Service")}
                                </span>{' '}
                                {t("and")}{' '}
                                <span onClick={handlePrivacyClick} style={{ cursor: 'pointer', color: 'blue' }}>
                                    {t("Privacy Policy")}
                                </span>
                            </p>
                        </div>  
                    </form> 
                </div> 
            </div>
        </div>
        
    );
} 